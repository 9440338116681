// src/components/Shop/ProductDetail/ProductDetail.js

import React, { useState, useEffect, useCallback, useRef } from "react";
import { toast } from "react-toastify";
import { auth } from "../../../firebaseConfig";
import { onAuthStateChanged } from "firebase/auth";
import { useNavigate } from "react-router-dom";

import AuthModal from "../../Auth/AuthModal";
import ShutterstockSearch from "./ShutterstockSearch/ShutterstockSearch";
import ImageEditor from "./ImageEditor/ImageEditor";
import TextTab from "./TextTab/TextTab";
import UploadTab from "./UploadTab/UploadTab";
import CustomizeTab from "./CustomizeTab/CustomizeTab";
import "./ProductDetail.css";

export default function ProductDetail({
  selectedModel,
  overlayImageUrl,
  onOverlayImageSelect,
}) {
  const PROJECT_KEY = "ijrr_user";

  const [user, setUser] = useState(() =>
    JSON.parse(localStorage.getItem(PROJECT_KEY))
  );
  const [isAuthModalOpen, setIsAuthModalOpen] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pendingCartAction, setPendingCartAction] = useState(false);

  const [selectedImageUrl, setSelectedImageUrl] = useState("");
  const [activeTab, setActiveTab] = useState("tab1"); // Manage which tab is currently active
  const [selectedColors, setSelectedColors] = useState([]); // Store available colors for the selected model
  const [selectedSizes, setSelectedSizes] = useState({}); // Store available sizes for the selected model
  const [selectedText, setSelectedText] = useState(null); // Store the selected text object, if any
  const [isTextObjectSelected, setIsTextObjectSelected] = useState(false); // Determine if a text object is selected
  const [selectedQuantity, setSelectedQuantity] = useState(1); // Store the quantity selected by the user
  const [currentSelectedColorIndex, setCurrentSelectedColorIndex] = useState(0); // Track the selected color index
  const [selectedSize, setSelectedSize] = useState(null);

  const imageEditorRef = useRef(null); // Use a ref to interact with ImageEditor
  const [selectedModelState, setSelectedModel] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (firebaseUser) => {
      if (firebaseUser) {
        setUser(firebaseUser); // ✅ Usuario autenticado correctamente
        console.log(
          "✅ Usuario autenticado desde Firebase en ProductDetail:",
          firebaseUser
        );
      } else {
        setUser(null);
        console.log("❌ No hay usuario autenticado.");
      }
    });

    return () => unsubscribe();
  }, []);

  // Fetch initial product details on mount
  useEffect(() => {
    initializeDefaultProduct();
  }, []);

  // Fetch product data and set default model
  const initializeDefaultProduct = useCallback(() => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/public-inventory`)
      .then((response) => response.json())
      .then((data) => {
        const categories = data.categories || [];
        if (categories.length > 0 && categories[0].models.length > 0) {
          const firstModel = categories[0].models[0];
          updateProductModel(firstModel);
        } else {
          updateProductModel({
            model_id: null,
            name: "Default Product Name",
            price: "0.00",
            image: "../assets/images/defaults/t-2.png",
            inventories: [],
          });
        }
      })
      .catch((error) => console.error("❌ Error fetching categories:", error));
  }, []);

  // ✅ 2. Cuando el usuario seleccione un modelo en `Categories.js`, actualizar todo
  useEffect(() => {
    if (selectedModel) {
      console.log("📌 Modelo cambiado en ProductDetail:", selectedModel);
      updateProductModel(selectedModel);
    }
  }, [selectedModel]);

  const updateProductModel = (model) => {
    console.log("🎨 Actualizando modelo en ProductDetail:", model);

    if (!model) return;

    setSelectedModel(model);

    const colors =
      model.inventories?.map((inventory) => ({
        color: inventory.color,
        thumbnail: inventory.thumbnail,
        sizes: inventory.sizes,
      })) || [];

    setSelectedColors(colors);

    // ✅ Usamos la imagen principal del modelo si no hay inventarios
    let firstImage = model.image;

    if (model.inventories?.length > 0) {
      firstImage = model.inventories[0].thumbnail.replace("/thumbs/", "/"); // Tomamos la imagen de la primera variante
    }

    setSelectedImageUrl(firstImage); // ✅ Guardamos la imagen en el estado

    if (model.inventories?.length > 0) {
      const firstSizes = { ...model.inventories[0].sizes };
      setSelectedSizes(firstSizes);
      const firstSize = Object.keys(firstSizes)[0] || null;
      setSelectedSize(firstSize);
    } else {
      setSelectedSizes({});
      setSelectedSize(null);
    }

    setCurrentSelectedColorIndex(0);
  };

  // ✅ Forzamos la actualización de `selectedImageUrl` cada vez que cambia el modelo
  useEffect(() => {
    if (selectedModelState) {
      console.log(
        "🔄 Aplicando imagen de modelo actualizado:",
        selectedModelState.image
      );
      setSelectedImageUrl(selectedModelState.image);
    }
  }, [selectedModelState]);

  useEffect(() => {
    if (selectedModelState && selectedModelState.inventories) {
      const firstInventory = selectedModelState.inventories[0];
      if (firstInventory) {
        setSelectedSizes({ ...firstInventory.sizes });
        setSelectedSize(Object.keys(firstInventory.sizes)[0] || null);
      } else {
        setSelectedSizes({});
        setSelectedSize(null);
      }
    }
  }, [selectedModelState]);

  // ✅ 4. Manejar selección de color y actualizar la imagen
  const handleColorSelected = (colorIndex) => {
    const selectedColorInfo = selectedColors[colorIndex];
    if (selectedColorInfo) {
      setCurrentSelectedColorIndex(colorIndex); // ✅ Solo guardar el color seleccionado
    }
  };
  // Handle tab switching
  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
    if (tabId !== "tab2") {
      setIsTextObjectSelected(false); // Clear the selected text object when switching away from the text tab
    }
  };

  // Handle object selection in the ImageEditor (e.g., when a text or image is selected)
  // ✅ 5. Manejo de eventos en `ImageEditor`
  const handleObjectSelected = (object) => {
    if (object.type === "text") {
      setIsTextObjectSelected(true);
      setSelectedText(object.text);
    } else {
      setIsTextObjectSelected(false);
    }
  };

  const handleObjectDeselected = () => {
    setIsTextObjectSelected(false);
  };

  // Handle text addition from TextTab
  const handleTextAdded = (text) => {
    if (imageEditorRef.current) {
      imageEditorRef.current.addTextToCanvas(text);
    }
  };

  const handleTextUpdate = (newText) => {
    if (imageEditorRef.current) {
      imageEditorRef.current.updateSelectedText(newText);
    }
  };

  const handleFontChange = (fontFamily) => {
    if (imageEditorRef.current) {
      imageEditorRef.current.updateFontFamily(fontFamily);
    }
  };

  const handleColorChange = (color) => {
    if (imageEditorRef.current) {
      imageEditorRef.current.updateTextColor(color);
    }
  };

  const handleRotationChange = (rotation) => {
    if (imageEditorRef.current) {
      imageEditorRef.current.updateTextRotation(rotation);
    }
  };

  const handleOutlineChange = (outlineWidth) => {
    if (imageEditorRef.current) {
      imageEditorRef.current.updateTextOutline(outlineWidth);
    }
  };

  const handleOutlineColorChange = (color) => {
    if (imageEditorRef.current) {
      imageEditorRef.current.updateOutlineColor(color);
    }
  };

  const handleSizeChange = (size) => {
    console.log("📏 Changing text size to:", size);
    if (imageEditorRef.current) {
      console.log(
        "✅ Calling updateTextSize in ImageEditor.js with size:",
        size
      );
      imageEditorRef.current.updateTextSize(size);
    } else {
      console.warn("⚠ imageEditorRef.current is NULL");
    }
  };

  // Define handleImageUpload function to process uploaded image
  const handleImageUpload = (uploadedImage) => {
    // Set the uploaded image as the overlay image in the ImageEditor
    onOverlayImageSelect(uploadedImage);
  };

  useEffect(() => {
    const storedUser = JSON.parse(localStorage.getItem(PROJECT_KEY));
    if (storedUser) setUser(storedUser);
  }, []);

  const addToCart = () => {
    if (!user) {
      console.log("⚠ Usuario no autenticado, mostrando modal de login...");
      setIsAuthModalOpen(true);
      setPendingCartAction(true);
      return;
    }

    const isLogoSelected = overlayImageUrl;
    if (!isLogoSelected) {
      setIsErrorModalOpen(true);
      console.warn("⚠ Error: No se ha subido un logo.");
      return;
    }

    // Si hay logo, mostramos el modal de confirmación
    setIsConfirmationModalOpen(true);
  };

  const prepareOrderData = () => {
    if (!user) {
      console.error("⚠ User not authenticated");
      return null;
    }

    if (!selectedModelState) {
      console.error("⚠ No product selected");
      return null;
    }

    if (!selectedSize) {
      console.error("⚠ No size selected");
      return null;
    }

    if (!selectedColors || selectedColors.length === 0) {
      console.error("⚠ No colors available for selection");
      return null;
    }

    if (currentSelectedColorIndex < 0 || currentSelectedColorIndex >= selectedColors.length) {
      console.error("⚠ Invalid color selection");
      return null;
    }

    if (!overlayImageUrl) {
      console.error("⚠ No product preview image uploaded");
      return null;
    }

    const selectedColor = selectedColors[currentSelectedColorIndex]?.color;

    // Crear el payload
    const orderData = {
      user_id: user.uid,
      user_contact: {
        email: user.email,
      },
      product_details: {
        product_name: selectedModelState.name,
        product_id: selectedModelState.model_id,
        selected_color: selectedColor,
        selected_size: selectedSize,
        quantity: selectedQuantity > 0 ? selectedQuantity : 1,
        order_status: "pending", // Default status
      },
      product_preview: overlayImageUrl, // La imagen debe enviarse como archivo en una petición FormData
    };

    console.log("📦 Order Data:", JSON.stringify(orderData, null, 2));

    return orderData;
  };

  const downloadDesign = () => {
    if (imageEditorRef.current) {
      imageEditorRef.current.exportCanvasAsPNG();
    } else {
      console.warn("⚠ ImageEditor is not ready");
    }
  };

  const proceedToAddToCart = async () => {
    setLoading(true);
    let storedUser = JSON.parse(localStorage.getItem(PROJECT_KEY));
  
    if (!storedUser || !storedUser.uid) {
      console.warn("⚠ Usuario en localStorage no válido, intentando obtener desde Firebase...");
      storedUser = await fetchCurrentUser();
  
      if (!storedUser) {
        console.error("❌ Error: No se pudo recuperar al usuario.");
        return;
      }
  
      setUser(storedUser);
      localStorage.setItem(PROJECT_KEY, JSON.stringify(storedUser));
    }
  
    console.log("✅ Usuario validado para la compra");
    console.log("🔍 Usuario:", storedUser);
    console.log("📧 Email:", storedUser.email);
    console.log("🆔 Firebase UID:", storedUser.uid);
  
    const orderData = prepareOrderData(storedUser);
    if (!orderData) {
      console.warn("🚨 Order data is incomplete. Fix the issues before sending.");
      return;
    }
  
    try {
      const formData = new FormData();
      formData.append("user_id", orderData.user_id);
      formData.append("user_contact[email]", orderData.user_contact.email);
      formData.append("product_details[product_name]", orderData.product_details.product_name);
      formData.append("product_details[product_id]", orderData.product_details.product_id);
      formData.append("product_details[selected_color]", orderData.product_details.selected_color);
      formData.append("product_details[selected_size]", orderData.product_details.selected_size);
      formData.append("product_details[quantity]", orderData.product_details.quantity);
      formData.append("product_details[order_status]", orderData.product_details.order_status);
  
      let productPreviewBlob = null;
      const isLocal = ["localhost", "127.0.0.1"].includes(window.location.hostname);
  
      if (!isLocal && imageEditorRef.current) {
        console.log("🌍 Production mode: Exporting canvas image...");
        try {
          productPreviewBlob = await imageEditorRef.current.exportCanvasAsPNG();
          if (productPreviewBlob) {
            console.log("📸 Custom design added to formData!");
            formData.append("product_preview", productPreviewBlob, "custom_design.png");
          }
        } catch (error) {
          console.error("❌ Failed to generate canvas image:", error);
        }
      }
  
      // 🔥 If no custom image was generated, use the default image
      if (!productPreviewBlob) {
        console.warn("⚠ No custom design found, using default preview image.");
        const response = await fetch("/assets/images/defaults/default-canvas.png");
        const defaultBlob = await response.blob();
        formData.append("product_preview", defaultBlob, "preview.png");
      }
  
      console.log("📤 Sending order to API...");
      const apiResponse = await fetch(`${process.env.REACT_APP_API_BASE_URL}/orders`, {
        method: "POST",
        body: formData,
      });
  
      if (!apiResponse.ok) throw new Error("❌ API request failed");
  
      const data = await apiResponse.json();
      console.log("✅ Order successfully created:", data);
  
      // 🔥 Show success toast
      toast.success("Order placed successfully!", {
        position: "top-right",
        autoClose: 1500,
      });
  
      // 🔥 Close confirmation modal
      setIsConfirmationModalOpen(false);
  
      setTimeout(() => {
        navigate("/shopping-cart");
      }, 1500);
    } catch (error) {
      console.error("⚠ Error placing order:", error);
      toast.error("Failed to place order. Please try again.", {
        position: "top-right",
        autoClose: 3000,
      });
    } finally {
      setLoading(false);
    }
  };

  /**
  * TODO: In the future, allow users to upload custom logos and include them in the order data.
  * For now, we only send the product preview.
  */

  useEffect(() => {
    if (user && pendingCartAction) {
      console.log("🛒 Usuario se logueó, procediendo con la compra...");
      setPendingCartAction(false);
      proceedToAddToCart();
    }
  }, [user]);

  const handleLoginSuccess = async (loggedUser) => {
    console.log("✅ Usuario autenticado después de login:", loggedUser);

    // 🔥 Aseguramos que guardamos el usuario en un formato consistente
    const formattedUser = {
      uid: loggedUser.uid,
      email: loggedUser.email,
      displayName: loggedUser.displayName,
    };

    setUser(formattedUser);
    localStorage.setItem(PROJECT_KEY, JSON.stringify(formattedUser)); // 🔥 Guardamos el usuario autenticado
    setIsAuthModalOpen(false);
  };

  const fetchCurrentUser = async () => {
    return new Promise((resolve) => {
      const unsubscribe = onAuthStateChanged(auth, (firebaseUser) => {
        unsubscribe(); // Nos desuscribimos después de obtener el usuario

        if (firebaseUser) {
          console.log("🔄 Usuario recargado desde Firebase:", firebaseUser);
          resolve({
            uid: firebaseUser.uid,
            email: firebaseUser.email,
            displayName: firebaseUser.displayName,
          });
        } else {
          resolve(null);
        }
      });
    });
  };

  useEffect(() => {
    const storedUser = JSON.parse(localStorage.getItem(PROJECT_KEY));
    if (storedUser) {
      setUser(storedUser);
    }
  }, []);

  const handleErrorClose = () => {
    setIsErrorModalOpen(false);
  };

  return (
    <section className="product-detail-page">
      <div className="custom-container">
        <div className="row">
          {/* Sidebar */}
          <div className="col-md-4 cdx-xl-55 product-options">
            <div className="product-card">
              <div className="row product-detailright">
                <div className="detail-group">
                  <div className="media">
                    <div>
                      {/* Display selected model name and price */}
                      <h2>{selectedModelState?.name || "Product Name"}</h2>
                      <h6 className="text-light">Special price</h6>
                      <ul className="product-price">
                        <li className="new-price">
                          ${selectedModelState?.price || "0.00"}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              {/* Vertical Tabs */}
              <div className="row">
                <div className="col-md-2 cdx-xl-55 tabs-wrapper">
                  <div className="vertical-tabs-container">
                    <ul className="nav flex-column vertical-nav-tabs">
                      {/* Tab buttons */}
                      <li>
                        <button className={`nav-link ${activeTab === "tab1" ? "active" : ""}`}
                          onClick={() => handleTabClick("tab1")}
                        >
                          <i className="fa fa-search"></i>
                          <span className="tab-title">Search</span>
                        </button>
                      </li>
                      <li>
                        <button className={`nav-link ${activeTab === "tab2" ? "active" : ""}`}
                          onClick={() => handleTabClick("tab2")}
                        >
                          <i className="fa fa-font"></i>
                          <span className="tab-title">Text</span>
                        </button>
                      </li>
                      <li>
                        <button className={`nav-link ${activeTab === "tab3" ? "active" : ""}`}
                          onClick={() => handleTabClick("tab3")}
                        >
                          <i className="fa fa-upload"></i>
                          <span className="tab-title">Upload</span>
                        </button>
                      </li>
                      <li>
                        <button className={`nav-link ${activeTab === "tab4" ? "active" : ""}`}
                          onClick={() => handleTabClick("tab4")}
                        >
                          <i className="fa fa-tshirt"></i>
                          <span className="tab-title">Customize</span>
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>

                {/* Tab Content */}
                <div className="col-md-10">
                  <div className="tab-content vertical-tab-content">
                    {/* Tab 1: Search */}
                    {activeTab === "tab1" && (
                      <div className="tab-pane fade show active">
                        <ShutterstockSearch onImageSelected={onOverlayImageSelect} // Pass the handler
                          for overlay selection />
                      </div>
                    )}
                    {/* Tab 2: Text */}
                    {activeTab === "tab2" && (
                      <div className="tab-pane fade show active">
                        <TextTab isTextObjectSelected={isTextObjectSelected} selectedText={selectedText}
                          onTextAdded={handleTextAdded} onTextUpdate={handleTextUpdate}
                          onFontChange={handleFontChange} onColorChange={handleColorChange}
                          onRotationChange={handleRotationChange}
                          onOutlineChange={handleOutlineChange}
                          onOutlineColorChange={handleOutlineColorChange} onSizeChange={(size) =>
                            console.log("Size Changed:", size)
                          }
                        />
                      </div>
                    )}
                    {/* Tab 3: Upload */}
                    {activeTab === "tab3" && (
                      <div className="tab-pane fade show active">
                        <UploadTab onImageUploaded={handleImageUpload} />{" "}
                        {/* UploadTab implementation */}
                      </div>
                    )}
                    {/* Tab 4: Customize */}
                    {activeTab === "tab4" && (
                      <div className="tab-pane fade show active">
                        {/* Personalización */}
                        <CustomizeTab initialColors={selectedColors} initialSizes={selectedSizes}
                          initialSelectedColorIndex={currentSelectedColorIndex}
                          onColorSelected={handleColorSelected} selectedSize={selectedSize} // ✅ Pasar
                          talla seleccionada setSelectedSize={setSelectedSize} // ✅ Pasar setter para
                          onQuantitySelected={setSelectedQuantity} actualizar
                          onImageChange={setSelectedImageUrl} />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Image Editor */}
          <div className="col-md-8 cdx-xl-45 image-editor-container">
            <div className="product-card">
              <div className="editor-wrap">
                {/* Pass selected image and overlay to ImageEditor */}
                <ImageEditor ref={imageEditorRef} selectedImageUrl={selectedImageUrl}
                  overlayImageUrl={overlayImageUrl} onObjectSelected={handleObjectSelected}
                  onObjectDeselected={handleObjectDeselected} onTextAdded={handleTextAdded} />
                {/* Add to Cart Button */}
                <div className="detail-group editor-actions">
                  <button className="btn btn-primary" onClick={addToCart}>
                    Add to cart
                  </button>

                  {/*<button className="btn btn-secondary" onClick={downloadDesign}>Download Design
                                </button>*/}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <AuthModal isOpen={isAuthModalOpen} onClose={() => setIsAuthModalOpen(false)}
        onLoginSuccess={handleLoginSuccess}
      />

      {/* Error Modal */}
      {isErrorModalOpen && (
        <div className="error-modal" onClick={() => setIsErrorModalOpen(false)}>
          <div className="error-content" onClick={(e) => e.stopPropagation()}>
            <button className="close-btn" onClick={() => setIsErrorModalOpen(false)}
            >
              ×
            </button>
            <h3>Before you can proceed with the purchase</h3>
            <p>You must select or upload at least one logo.</p>
            <button className="btn btn-primary" onClick={() => setIsErrorModalOpen(false)}
            >
              Close
            </button>
          </div>
        </div>
      )}

      {/* Confirmation Modal */}
      {isConfirmationModalOpen && (
        <div className="error-modal" onClick={() => setIsConfirmationModalOpen(false)}>
          <div className="error-content" onClick={(e) => e.stopPropagation()}>
            <button className="close-btn" onClick={() => setIsConfirmationModalOpen(false)}>×</button>
            <h3>Confirm Purchase</h3>
            <p>Are you sure you want to add this item to your cart?</p>
            <div className="error-buttons">
              <button className="btn-secondary" onClick={() => setIsConfirmationModalOpen(false)}
                disabled={loading}>
                Cancel
              </button>
              <button className="btn-primary" onClick={proceedToAddToCart} disabled={loading}>
                {loading ? "Processing..." : "Confirm"}
              </button>
            </div>

            {/* Small Loader */}
            {loading && (
              <div className="loading-spinner-container">
                <div className="spinner"></div>
              </div>
            )}
          </div>
        </div>
      )}
    </section>
  );
}
