// src/components/Shop/Categories/Categories.js

import React, { useState, useEffect, useMemo } from "react";
import "./Categories.css";

export default function Categories({ onModelSelect }) {
  const [categories, setCategories] = useState([]);
  const [currentTab, setCurrentTab] = useState("tab-1");

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/public-inventory`
        );
        const data = await response.json();
        setCategories(data.categories || []); // Asegurar que categories no sea undefined
      } catch (error) {
        console.error("❌ Error fetching categories:", error);
      }
    };

    fetchCategories();
  }, []);

  const tabs = useMemo(() => {
    return categories.map((category, index) => (
      <li key={category.id} className="nav-item">
        <button
          className={`btn btn-tab ${currentTab === `tab-${index + 1}` ? "active" : ""}`}
          onClick={() => setCurrentTab(`tab-${index + 1}`)}
        >
          {category.name}
        </button>
      </li>
    ));
  }, [categories, currentTab]);

  return (
    <section className="product-detail-page categories-space">
      <div className="custom-container">
        <div className="row">
          <div className="col-sm-12">
            <div className="product-card product-detail-tab">
              {/* Tabs Navigation */}
              <ul className="nav nav-tabs">{tabs}</ul>

              {/* Tab Content */}
              <div className="tab-content">
                {categories.map((category, index) => (
                  <div
                    key={category.id}
                    id={`tab-${index + 1}`}
                    className={`tab-pane fade ${currentTab === `tab-${index + 1}` ? "active show" : ""}`}
                  >
                    <div className="custom-container">
                      <div className="row">
                        <div className="col-12">
                          <div className="categoryslide-8 left-slider arrow-hide">
                            {/* Verificar que category.models no sea undefined antes de hacer map */}
                            {category.models && category.models.length > 0 ? (
                              category.models.map((model) => (
                                <div key={model.name} className="category-grid">
                                  <div className="img-wrap">
                                    <button
                                      className="image-button"
                                      onClick={() => {
                                        console.log("📌 Modelo seleccionado:", model);
                                        onModelSelect(model);
                                      }}
                                    >
                                      <img
                                        className="img-fluid"
                                        src={model.image}
                                        alt={model.name}
                                      />
                                    </button>
                                  </div>
                                  <div className="detail-wrap">
                                    <button
                                      className="detail-button"
                                      onClick={() => {
                                        console.log("📌 Modelo seleccionado:", model);
                                        onModelSelect(model);
                                      }}
                                    >
                                      <h4>{model.name}</h4>
                                    </button>
                                  </div>
                                </div>
                              ))
                            ) : (
                              <p>No models available</p> // Mensaje en caso de que no haya modelos
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>

            </div>
          </div>
        </div>
      </div>
    </section>
  );
}