// src/components/HomeSlider/HomeSlider.js

import React, { useRef, useEffect, useState } from "react";
import './HomeSlider.css';
import { FaVolumeMute, FaVolumeUp } from "react-icons/fa"; // Import volume icons

export default function HomeSlider() {
  const videoRef = useRef(null);
  const [isMuted, setIsMuted] = useState(true); // Track mute state

  const toggleMute = () => {
    if (videoRef.current) {
      videoRef.current.muted = !videoRef.current.muted; // Toggle mute state
      setIsMuted(videoRef.current.muted); // Update state
    }
  };

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.muted = true; // Start muted
      videoRef.current.load(); // Ensure the video is reloaded
      videoRef.current.play().catch(() => {
        console.warn("Autoplay failed due to browser restrictions.");
      });
    }
  }, []);

  return (
    <section className="home-space">
      <div className="homearrowlide-1 arrow-style1">
        <div>
          <div className="fashion-slider">
            {/* Background Video */}
            <video
              ref={videoRef}
              className="video-background"
              muted
              loop
              playsInline
              poster="/assets/videos/intro-video/intro-video-lg.jpg"
            >
              <source
                src="/assets/videos/intro-video/intro-video-lg.mp4"
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>

            {/* Mute/Unmute Button */}
            <div className="mute-button-container" onClick={toggleMute}>
              {isMuted ? (
                <FaVolumeMute className="mute-icon" />
              ) : (
                <FaVolumeUp className="mute-icon" />
              )}
            </div>

            <div className="custom-container">
              <div className="row">
                <div className="col-12">
                  {/* Optional content */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
