// src/components/Shop/ProductDetail/CustomizeTab/CustomizeTab.js

import React, { useState, useEffect } from 'react';
import './CustomizeTab.css';

export default function CustomizeTab({
  initialColors = [],
  initialSizes = {},
  initialSelectedColorIndex = 0,
  onColorSelected,
  onSizeSelected,
  onQuantitySelected,
  selectedSize,
  setSelectedSize,
  onImageChange // ✅ Nueva prop para actualizar la imagen de fondo
}) {
  const [selectedColors, setSelectedColors] = useState(initialColors);
  const [selectedSizes, setSelectedSizes] = useState({});
  const [selectedColorIndex, setSelectedColorIndex] = useState(initialSelectedColorIndex);
  const [selectedQuantity, setSelectedQuantity] = useState(1);
  const [availableQuantities, setAvailableQuantities] = useState([]);

  // ✅ Sincronizar `initialColors` y `initialSelectedColorIndex` con el estado
  useEffect(() => {
    setSelectedColors(initialColors);
    setSelectedColorIndex(initialSelectedColorIndex);

    if (initialColors.length > 0) {
      selectColor(initialSelectedColorIndex);
    } else {
      setSelectedSizes({});
      setSelectedSize(null);
      setAvailableQuantities([]);
    }
  }, [initialColors, initialSelectedColorIndex, setSelectedSize]);

  // ✅ Sincronizar `initialSizes` con el estado
  useEffect(() => {
    if (initialSizes && Object.keys(initialSizes).length > 0) {
      setSelectedSizes(initialSizes);
      setSelectedSize(Object.keys(initialSizes)[0] || null); // 🔥 Seleccionar la primera talla disponible
    } else {
      setSelectedSizes({});
      setSelectedSize(null);
    }
  }, [initialSizes, setSelectedSize]);

  // ✅ Sincronizar tallas disponibles cuando cambia `selectedSize`
  useEffect(() => {
    if (selectedSize && selectedSizes[selectedSize]) {
      updateAvailableQuantities();
    } else {
      setAvailableQuantities([]);
      setSelectedQuantity(1);
    }
  }, [selectedSize, selectedSizes]);

  // ✅ 1. Seleccionar color y actualizar tallas disponibles
  const selectColor = (index) => {
    setSelectedColorIndex(index);
    const colorInfo = selectedColors[index];

    if (colorInfo && colorInfo.sizes) {
      setSelectedSizes(colorInfo.sizes);
      const firstSize = Object.keys(colorInfo.sizes)[0] || null;
      setSelectedSize(firstSize);
      updateAvailableQuantities(firstSize);
      setSelectedQuantity(1);

      // ✅ Obtener la imagen completa de la variante en lugar del thumbnail
      const fullImageUrl = colorInfo.thumbnail.replace('/thumbs/', '/');

      // ✅ Llamamos a `onImageChange` para actualizar la imagen de fondo
      onImageChange(fullImageUrl);

      // Emitir eventos
      onColorSelected && onColorSelected(index);
      onSizeSelected && onSizeSelected(firstSize);
      onQuantitySelected && onQuantitySelected(1);
    } else {
      console.warn(`⚠ No sizes found for color at index ${index}`);
      setSelectedSizes({});
      setSelectedSize(null);
      setAvailableQuantities([]);
    }
  };

  // ✅ 2. Seleccionar talla y actualizar cantidades disponibles
  const selectSize = (size) => {
    setSelectedSize(size);
    updateAvailableQuantities(size);
    setSelectedQuantity(1);

    // Emitir eventos
    onSizeSelected && onSizeSelected(size);
    onQuantitySelected && onQuantitySelected(1);
  };

  // ✅ 3. Obtener cantidades disponibles para la talla seleccionada
  const updateAvailableQuantities = (size = selectedSize) => {
    if (!size || !selectedSizes[size]) {
      setAvailableQuantities([]);
      return;
    }

    const quantity = selectedSizes[size];
    const newQuantities = Array.from({ length: quantity }, (_, i) => i + 1);
    setAvailableQuantities(newQuantities);
  };

  const selectQuantity = (quantity) => {
    setSelectedQuantity(quantity);
    onQuantitySelected && onQuantitySelected(quantity); // 🔥 Avisamos a `ProductDetail.js`
  };

  return (
    <div className="product-detailright">
      <div className="product-card">

        {/* 🎨 Selección de Color */}
        <div className="detail-group">
          <h6 className="label-tab-container">Choose your perfect color</h6>
          <ul className="product-color">
            {selectedColors.map((colorInfo, index) => (
              <li
                key={colorInfo.color}
                className={selectedColorIndex === index ? "active" : ""}
                onClick={() => selectColor(index)}
                data-color={colorInfo.color} // Agregar atributo para CSS
              >
                <div style={{ backgroundImage: `url(${colorInfo.thumbnail})` }}></div>
              </li>
            ))}
          </ul>
        </div>

        {/* 📏 Selección de Talla */}
        <div className="detail-group">
          <h6 className="label-tab-container">Select the right size for you</h6>
          <ul className="product-size">
            {Object.keys(selectedSizes).map((size) => (
              <li
                key={size}
                className={selectedSize === size ? 'active' : ''}
                onClick={() => selectSize(size)}
              >
                <button>{size}</button>
              </li>
            ))}
          </ul>
        </div>

        {/* 🔢 Selección de Cantidad */}
        <div className="detail-group">
          <h6 className="label-tab-container">Select quantity for your order</h6>
          <select
            value={selectedQuantity}
            onChange={(e) => selectQuantity(Number(e.target.value))}
          >
            {availableQuantities.map((n) => (
              <option key={n} value={n}>
                {n}
              </option>
            ))}
          </select>
        </div>

      </div>
    </div>
  );
}