// src/utils/loadFonts.js

import WebFont from 'webfontloader';

export const loadFonts = () => {
  WebFont.load({
    custom: {
      families: [
        'Black-Pearl',
        'Pirate-Treasure',
        'Caribbean',
        'Jolly-Roger',
        'Skulls',
        'Buccaneer',
        'Sea-Dog'
      ],
      urls: ['/assets/css/custom-fonts.css']
    },
    active() {
      console.log('✅ Custom fonts loaded!');
    },
    inactive() {
      console.warn('⚠️ Custom fonts failed to load!');
    }
  });
};