// src/App.js

import React from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import Header from './layout/Header/Header';
import Footer from './layout/Footer/Footer';
import Home from './components/Home/Home';
import About from './components/About/About';
import Tradition from './components/Tradition/Tradition';
import Maps from './components/Maps/Maps';
import Shop from './components/Shop/Shop';
import Pricing from './components/Pricing/Pricing';
import Profile from './components/Profile/Profile';
import Register from './components/Register/Register';
import Login from './components/Login/Login';
import ShoppingCart from './components/ShoppingCart/ShoppingCart';
import CustomIndex from './components/CustomIndex/CustomIndex';

function Layout() {
  const location = useLocation(); // Get the current location
  return (
    <>
      <Header />
      <main>
        <Routes>
          <Route path="home" element={<Home key={location.pathname} />} /> {/* Force remount */}
          <Route path="about" element={<About />} />
          <Route path="tradition" element={<Tradition />} />
          <Route path="maps" element={<Maps />} />
          <Route path="shop" element={<Shop />} />
          <Route path="pricing" element={<Pricing />} />
          <Route path="profile" element={<Profile />} />
          <Route path="login" element={<Login />} />
          <Route path="register" element={<Register />} />
          <Route path="shopping-cart" element={<ShoppingCart />} />
        </Routes>
      </main>
      <Footer />
    </>
  );
}

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<CustomIndex />} />
        <Route path="*" element={<Layout />} />
      </Routes>
    </Router>
  );
}

export default App;
