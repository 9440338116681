// src/components/Shop/ProductDetail/TextTab/TextTab.js

import React, { useState, useEffect } from 'react';
import './TextTab.css';

export default function TextTab({ 
  isTextObjectSelected, 
  selectedText, 
  sizeOptions = [10, 12, 14, 16, 20, 24, 28, 32, 40, 48],
  onTextAdded, 
  onTextUpdate, 
  onFontChange, 
  onColorChange, 
  onRotationChange, 
  onOutlineChange, 
  onOutlineColorChange, 
  onSizeChange 
}) {
  const [userText, setUserText] = useState('');
  const [rotation, setRotation] = useState(0);
  const [selectedSize, setSelectedSize] = useState(20);
  const [selectedOutlineColor, setSelectedOutlineColor] = useState('#B4B4B8');
  const [selectedOutlineWidth, setSelectedOutlineWidth] = useState(0);

  // ✅ Sincroniza el input con el texto seleccionado en `ImageEditor`
  useEffect(() => {
    if (isTextObjectSelected && selectedText) {
      console.log("📌 Setting selected text:", selectedText);
      setUserText(selectedText);
    } else {
      setUserText('');
    }
  }, [isTextObjectSelected, selectedText]);

  // ✅ Cuando escribimos en el input, actualiza el texto en `ImageEditor`
  const handleTextChange = (e) => {
    const newText = e.target.value;
    setUserText(newText);
    onTextUpdate(newText); // Envía el nuevo texto al editor
  };

  // ✅ Agregar nuevo texto si no hay ninguno seleccionado
  const handleTextAdd = () => {
    if (userText.trim()) {
      onTextAdded(userText);
      setUserText('');
    }
  };

  const handleFontChange = (e) => {
    const selectedFont = e.target.value;
    onFontChange(selectedFont);
  };

  const handleColorChange = (e) => {
    const selectedColor = e.target.value;
    onColorChange(selectedColor);
  };

  const handleRotationChange = (e) => {
    const newRotation = parseInt(e.target.value, 10);
    setRotation(newRotation);
    onRotationChange(newRotation);
  };

  const handleOutlineChange = (e) => {
    const newOutlineWidth = parseFloat(e.target.value);
    setSelectedOutlineWidth(newOutlineWidth);
    onOutlineChange(newOutlineWidth); // 🔥 Envía el nuevo grosor al editor
  };
  
  const handleOutlineColorChange = (e) => {
    const newColor = e.target.value;
    setSelectedOutlineColor(newColor);
    onOutlineColorChange(newColor); // 🔥 Envía el nuevo color al editor
  };

  useEffect(() => {
    if (isTextObjectSelected) {
      onSizeChange(selectedSize);
    }
  }, [selectedSize, isTextObjectSelected, onSizeChange]);

  const handleSizeChange = (e) => {
    const newSize = parseInt(e.target.value, 10);
    console.log("📏 Selected size:", newSize); // Debug log
    setSelectedSize(newSize);
    onSizeChange(newSize);
  };

  return (
    <div>
      {isTextObjectSelected ? (
        <div className="text-editor-container">
          <form>
            {/* ✅ Campo de entrada para modificar el texto seleccionado */}
            <div className="input-group">
              <input
                type="text"
                className="form-control"
                placeholder="Edit your text"
                value={userText}
                onChange={handleTextChange}
              />
            </div>
            <div className="settings-container">
              {/* Configuración de fuente */}
              <div className="font-settings settings-row">
                <label htmlFor="font-select">Font</label>
                <select id="font-select" className="form-select" onChange={handleFontChange}>
                  <option value="Trebuchet MS">Trebuchet MS</option>
                  <option value="Black-Pearl">Black Pearl</option>
                  <option value="Pirate-Treasure">Pirate Treasure</option>
                  <option value="Caribbean">Caribbean</option>
                  <option value="Jolly-Roger">Jolly Roger</option>
                  <option value="Skulls">Skulls and Crossbones</option>
                  <option value="Buccaneer">Buccaneer</option>
                  <option value="Sea-Dog">Sea Dog</option>
                  <option value="TimesNewRoman">Times New Roman</option>
                  <option value="Arial">Arial</option>
                  <option value="Courier New">Courier New</option>
                  <option value="Georgia">Georgia</option>
                  <option value="Verdana">Verdana</option>
                </select>
              </div>
              {/* Configuración de color */}
              <div className="color-settings settings-row">
                <label htmlFor="color-select">Text Color</label>
                <input type="color" id="color-select" onChange={handleColorChange} />
              </div>
              {/* Configuración de rotación */}
              {/* Rotación del texto */}
              <div className="rotation-settings settings-row">
                <label htmlFor="rotation-range">Rotation</label>
                <input
                  type="range"
                  id="rotation-range"
                  min="-180"
                  max="180"
                  value={rotation}
                  onChange={handleRotationChange}
                />
              </div>
              {/* Configuración de contorno */}
              <div className="outline-settings settings-row">
                <label htmlFor="outline-select" className="settings-label">Outline</label>
                <input type="color" id="outline-color-select" value={selectedOutlineColor} onChange={handleOutlineColorChange} />
                <select id="outline-width" className="form-select" value={selectedOutlineWidth} onChange={handleOutlineChange}>
                  <option value="0">No Outline</option>
                  <option value="0.1">Ultra Thin</option>
                  <option value="0.2">Very Thin</option>
                  <option value="0.3">Thin</option>
                  <option value="0.5">Light</option>
                  <option value="0.7">Medium</option>
                  <option value="1">Bold</option>
                  <option value="1.5">Thick</option>
                  <option value="2">Extra Thick</option>
                </select>
              </div>
              {/* Configuración de tamaño */}
              <div className="size-settings settings-row">
                <label htmlFor="size-select">Text Size</label>
                <select id="size-select" className="form-select" value={selectedSize} onChange={handleSizeChange}>
                  {sizeOptions.map((size) => (
                    <option key={size} value={size}>
                      {size}px
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </form>
        </div>
      ) : (
        <div className="text-input-container">
          <h5 className="label-tab-container">Add personalized text here</h5>
          <input
            type="text"
            className="form-control text-input"
            placeholder="Enter text here"
            value={userText}
            onChange={(e) => setUserText(e.target.value)}
          />
          <button className="btn btn-primary text-button" onClick={handleTextAdd}>
            Add To Design
          </button>
        </div>
      )}
    </div>
  );
}