// src/components/Register/Register.js

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { auth, googleProvider } from "../../firebaseConfig";
import { createUserWithEmailAndPassword, signInWithPopup, updateProfile } from "firebase/auth";
import "react-toastify/ReactToastify.css";
import "./Register.css";

const PROJECT_KEY = "ijrr_user"; // 🔥 Clave única para IJRR

export default function Register({ onLoginSuccess }) {
    const navigate = useNavigate();
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [mobile, setMobile] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [termsAgreed, setTermsAgreed] = useState(false);

    const clearForm = () => {
        setFirstName("");
        setLastName("");
        setMobile("");
        setEmail("");
        setPassword("");
        setConfirmPassword("");
        setTermsAgreed(false);
    };

    const handleSignUp = async (e) => {
        e.preventDefault();

        if (password !== confirmPassword) {
            toast.error("Passwords do not match", {autoClose: 2000});
            return;
        }

        if (!termsAgreed) {
            toast.error("You must agree to the terms and conditions", {autoClose: 2000});
            return;
        }

        try {
            const userCredential = await createUserWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;

            await updateProfile(user, {
                displayName: `${firstName} ${lastName}`
            });

            const userData = {
                firebase_uid: user.uid,
                first_name: firstName,
                last_name: lastName,
                mobile: mobile,
                email: user.email,
                sign_up_method: "email_pass",
                status: "active",
                created_at_firebase: new Date(user.metadata.creationTime).toISOString(),
                last_login_at_firebase: new Date(user.metadata.lastSignInTime).toISOString(),
            };

            await registerUser(userData);

            localStorage.setItem(PROJECT_KEY, JSON.stringify({ ...user, displayName: `${firstName} ${lastName}` }));
            console.log("✅ User registered successfully!", user);

            toast.success("User registered successfully!", {autoClose: 1000});

            setTimeout(() => {
                navigate("/profile");
            }, 1500);

            //setTimeout

            //onLoginSuccess(user);
            clearForm();
        } catch (error) {
            console.error("❌ Registration error:", error);
            toast.error("Registration failed. Please try again.", {autoClose: 2000});
        }
    };

    const handleGoogleSignIn = async () => {
        try {
            const result = await signInWithPopup(auth, googleProvider);
            const user = result.user;

            const names = user.displayName ? user.displayName.split(" ") : ["", ""];
            const userData = {
                firebase_uid: user.uid,
                first_name: names[0],
                last_name: names.slice(1).join(" "),
                email: user.email,
                avatar: user.photoURL,
                sign_up_method: "google",
                status: "active",
                created_at_firebase: new Date(user.metadata.creationTime).toISOString(),
                last_login_at_firebase: new Date(user.metadata.lastSignInTime).toISOString(),
            };

            await registerUser(userData);

            localStorage.setItem(PROJECT_KEY, JSON.stringify(user)); // 🔥 Guardamos SOLO para IJRR
            console.log("✅ Google Sign-In Successful!", user);

            toast.success("User registered successfully!", {autoClose: 1000});

            setTimeout(() => {
                navigate("/profile");
            }, 1500);

            //onLoginSuccess(user);
        } catch (error) {
            console.error("❌ Google sign-in error:", error);
            toast.error("Google sign-in failed!", {autoClose: 1000});
        }
    };

    const registerUser = async (userData) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/customers`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(userData),
            });

            if (!response.ok) {
                const errorData = await response.json();
                console.error("❌ Error response data:", errorData);
                toast.error("Failed to save user data. Please try again.", {autoClose: 1000});
            } else {
                console.log("✅ User registered successfully!");
                //toast.success("User registered successfully!", {autoClose: 1000});
            }
        } catch (error) {
            console.error("❌ Failed to register user:", error);
            toast.error("Error connecting to the server.", {autoClose: 1000});
        }
    };

    return (
        <section className="space-pb-80 product-detail-page">
            <ToastContainer />
            <div className="register-container">
                <div className="codex-authmain">
                    <form className="codex-authbox" onSubmit={handleSignUp}>
                        <div className="group-form text-center">
                            <h3>Create Your Account</h3>
                            <h6>
                                Already have an account?{" "}
                                <a className="text-primary text-decoration-underline" href="/login">
                                    Login here
                                </a>
                            </h6>
                        </div>

                        <div className="group-form auth-two-columns">
                            <div>
                                <label className="form-label">First Name</label>
                                <input className="form-control" type="text" placeholder="Edward" value={firstName} onChange={(e) => setFirstName(e.target.value)} required />
                            </div>
                            <div>
                                <label className="form-label">Last Name</label>
                                <input className="form-control" type="text" placeholder="Teach" value={lastName} onChange={(e) => setLastName(e.target.value)} required />
                            </div>
                        </div>

                        <div className="group-form">
                            <label className="form-label">Mobile</label>
                            <input className="form-control" type="tel" placeholder="+1-555-JOLLY-R" value={mobile} onChange={(e) => setMobile(e.target.value)} required />
                        </div>

                        <div className="group-form">
                            <label className="form-label">Email</label>
                            <input className="form-control" type="email" placeholder="mail@jollyroger.us.com" value={email} onChange={(e) => setEmail(e.target.value)} required />
                        </div>

                        <div className="group-form auth-two-columns">
                            <div>
                                <label className="form-label">Password</label>
                                <input className="form-control" type="password" value={password} onChange={(e) => setPassword(e.target.value)} required />
                            </div>
                            <div>
                                <label className="form-label">Confirm Password</label>
                                <input className="form-control" type="password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} required />
                            </div>
                        </div>
                        
                        <div className="group-form">
                            <label className="auth-checkbox">
                                <input type="checkbox" checked={termsAgreed} onChange={(e) => setTermsAgreed(e.target.checked)} className="register-chekcbox" />
                                I Agree to Terms and Conditions
                            </label>
                        </div>
                        
                        <button type="submit" className="btn d-block btn-primary-register">Sign up</button>

                        <button type="button" className="auth-google-btn" onClick={handleGoogleSignIn}>
                            Sign in with Google
                        </button>
                    </form>
                </div>
            </div>
        </section>
    );
}