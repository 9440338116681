// src/components/Pricing/Pricing.js

import React, { useState, useEffect } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import "./Pricing.css"; // IMPORTANTE: Importar el CSS

export default function Pricing() {
  const [models, setModels] = useState([]);

  useEffect(() => {
    const fetchModels = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/public-inventory`
        );
        const data = await response.json();

        const allModels = data.categories.flatMap((category) => category.models || []);
        setModels(allModels);
      } catch (error) {
        console.error("❌ Error fetching models:", error);
      }
    };

    fetchModels();
  }, []);

  const sliderSettings = {
    infinite: false,
    speed: 350,
    slidesToShow: 3,
    slidesToScroll: 1,
    dots: true,
    autoplay: true,
    responsive: [
      { breakpoint: 1441, settings: { slidesToShow: 3 } },
      { breakpoint: 1024, settings: { slidesToShow: 2 } },
      { breakpoint: 600, settings: { slidesToShow: 1 } },
    ],
  };

  return (
    <section className="space-pb-80 product-detail-page">
      <div className="custom-container">
        
        {/* Welcome Section */}
        <div className="text-center mb-5">
          <h2>WELCOME MATTIES</h2>
          <p>Pick the best plan that meets your PERSONAL FLAG needs.</p>
          <br />
        </div>

        {/* Related Products Section */}
        <div className="related-product mb-20">
          <Slider {...sliderSettings}>
            {models.length > 0 ? (
              models.map((model) => (
                <div key={model.model_id} className="product-boxwrap">
                  <div className="product-imgwrap">
                    <a href="/">
                      <img className="img-fluid" src={model.image} alt={model.name} />
                    </a>
                    <ul className="social">
                      <li><a href="/shop"><i className="fa fa-shopping-cart"></i></a></li>
                      <li><a href="/shop"><i className="fa fa-eye"></i></a></li>
                    </ul>
                  </div>
                  <div className="product-detailwrap">
                    <a href="/">
                      <h5>{model.name}</h5>
                    </a>
                    <div className="pro-price">
                      ${model.price}
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <p className="text-center">No products available.</p>
            )}
          </Slider>
        </div>

        {/* Note Section */}
        <div className="mt-5">
          <p>Note: 2' x 3' Flag is the standard size, polyester and rectangle shape.</p>
          <p>· Many different shapes and sizes and materials</p>
        </div>
      </div>
    </section>
  );
}