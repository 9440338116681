// src/components/Shop/ProductDetail/ImageEditor/ImageEditor.js

import React, { useEffect, useImperativeHandle, forwardRef } from 'react';
import { FabricJSCanvas, useFabricJSEditor } from 'fabricjs-react';
import './ImageEditor.css';

const ImageEditor = forwardRef(({ selectedImageUrl, overlayImageUrl, onObjectSelected, onObjectDeselected }, ref) => {
  const { editor, onReady } = useFabricJSEditor();
  const baseImage = '/assets/images/defaults/t-2.png';
  const snapThreshold = 10;

  useImperativeHandle(ref, () => ({
    exportCanvasAsPNG() {
      return new Promise((resolve, reject) => {
          try {
              if (!editor?.canvas) {
                  reject(new Error("Canvas reference is missing."));
                  return;
              }
  
              // Convert canvas to DataURL
              const dataURL = editor.canvas.toDataURL({
                  format: "png",
                  quality: 1.0,
              });
  
              // Convert DataURL to Blob
              fetch(dataURL)
                  .then((res) => res.blob())
                  .then((blob) => resolve(blob))
                  .catch((error) => reject(error));
  
          } catch (error) {
              reject(error);
          }
      });
  },
    exportAndDownloadCanvasAsPNG() {
      if (!editor?.canvas) {
        console.warn("⚠ Fabric.js editor is not initialized");
        return;
      }
  
      const dataURL = editor.canvas.toDataURL({
        format: "png",
        quality: 1.0,
      });
  
      const downloadLink = document.createElement("a");
      downloadLink.href = dataURL;
      downloadLink.download = "custom_design.png";
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
  
      console.log("✅ Canvas exported as PNG");
    },
    addTextToCanvas(text) {
      if (editor?.canvas) {
        const canvasCenter = editor.canvas.getCenter();
        const textObject = new window.fabric.Text(text, {
          left: canvasCenter.left,
          top: canvasCenter.top,
          fontFamily: 'Arial',
          fill: '#fff',
          fontSize: 20,
          originX: 'center',
          originY: 'center',
        });
        editor.canvas.add(textObject).setActiveObject(textObject);
        editor.canvas.renderAll();
      }
    },
    updateSelectedText(newText) {
      if (editor?.canvas) {
        const activeObject = editor.canvas.getActiveObject();
        if (activeObject && activeObject.type === "text") {
          activeObject.set("text", newText);
          editor.canvas.renderAll();
        }
      }
    },
    updateFontFamily(fontFamily) {
      if (editor?.canvas) {
        const activeObject = editor.canvas.getActiveObject();
        if (activeObject && activeObject.type === "text") {
          activeObject.set("fontFamily", fontFamily);
          editor.canvas.renderAll();
        }
      }
    },
    updateTextColor(color) {
      if (editor?.canvas) {
        const activeObject = editor.canvas.getActiveObject();
        if (activeObject && activeObject.type === "text") {
          activeObject.set("fill", color); // 🔥 Cambiar color del texto
          editor.canvas.renderAll();
        }
      }
    },
    updateTextRotation(rotation) {
      if (editor?.canvas) {
        const activeObject = editor.canvas.getActiveObject();
        if (activeObject && activeObject.type === "text") {
          activeObject.set("angle", rotation);
          editor.canvas.renderAll();
        }
      }
    },
    updateTextOutline(outlineWidth) {
      if (editor?.canvas) {
        const activeObject = editor.canvas.getActiveObject();
        if (activeObject && activeObject.type === "text") {
          activeObject.set("strokeWidth", outlineWidth); // 🔥 Cambia grosor del outline
          editor.canvas.renderAll();
        }
      }
    },
    updateOutlineColor(color) {
      if (editor?.canvas) {
        const activeObject = editor.canvas.getActiveObject();
        if (activeObject && activeObject.type === "text") {
          activeObject.set("stroke", color); // 🔥 Cambia color del outline
          editor.canvas.renderAll();
        }
      }
    },
    updateTextSize(size) {
      if (!editor?.canvas) {
        console.warn("⚠ Fabric.js editor is not initialized");
        return;
      }

      const activeObject = editor.canvas.getActiveObject();

      if (!activeObject || activeObject.type !== "text") {
        console.warn("⚠ No text object selected in Fabric.js");
        return;
      }

      console.log("🎯 Applying size change in Fabric.js:", size, "Current Object:", activeObject);
      activeObject.set("fontSize", size);
      editor.canvas.renderAll();

    }
  }));

  // Handle object selection
  const handleObjectSelected = (e) => {
    const object = e.selected?.[0];
    if (object?.type === 'text') {
      onObjectSelected(object);
    }
  };

  const handleObjectDeselected = () => {
    onObjectDeselected();
  };

  // Snap objects to center guidelines
  const handleObjectMoving = (e) => {
    const object = e.target;

    if (Math.abs(object.left - editor.verticalGuideLine) < snapThreshold) {
      object.set({ left: editor.verticalGuideLine, originX: 'center' });
      showVerticalGuideLine();
    }

    if (Math.abs(object.top - editor.horizontalGuideLine) < snapThreshold) {
      object.set({ top: editor.horizontalGuideLine, originY: 'center' });
      showHorizontalGuideLine();
    }

    editor.canvas.renderAll();
  };

  // ✅ **Inicializar guías de alineación**
  useEffect(() => {
    if (editor?.canvas) {
      const canvas = editor.canvas;
      editor.verticalGuideLine = canvas.getWidth() / 2;
      editor.horizontalGuideLine = canvas.getHeight() / 2;
    }
  }, [editor]);

  const showVerticalGuideLine = () => {
    if (editor.vLine) editor.canvas.remove(editor.vLine);
    editor.vLine = new window.fabric.Line([editor.verticalGuideLine, 0, editor.verticalGuideLine, editor.canvas.getHeight()], {
      stroke: '#808080', strokeWidth: 1, strokeDashArray: [4, 3], selectable: false, evented: false,
    });
    editor.canvas.add(editor.vLine);
    editor.canvas.renderAll();
  };

  const showHorizontalGuideLine = () => {
    if (editor.hLine) editor.canvas.remove(editor.hLine);
    editor.hLine = new window.fabric.Line([0, editor.horizontalGuideLine, editor.canvas.getWidth(), editor.horizontalGuideLine], {
      stroke: '#808080', strokeWidth: 1, strokeDashArray: [4, 3], selectable: false, evented: false,
    });
    editor.canvas.add(editor.hLine);
    editor.canvas.renderAll();
  };

  const hideGuideLines = () => {
    if (editor.vLine) {
      editor.canvas.remove(editor.vLine);
      editor.vLine = null;
    }
    if (editor.hLine) {
      editor.canvas.remove(editor.hLine);
      editor.hLine = null;
    }
    editor.canvas.renderAll();
  };


  const handleDeleteKey = (event) => {
    if (!editor?.canvas) return;

    const activeObject = editor.canvas.getActiveObject();

    // 🔥 Evita eliminar objetos si el usuario está escribiendo en un input o textarea
    if (document.activeElement.tagName === "INPUT" || document.activeElement.tagName === "TEXTAREA") {
      return;
    }

    // 🔥 Permite eliminar objetos solo con teclas específicas
    const isDeleteKey = event.key === "Delete" || event.key === "Backspace";
    const isMacDeleteKey = event.key === "Backspace" && event.metaKey; // Cmd + Backspace en Mac

    if ((isDeleteKey || isMacDeleteKey) && activeObject) {
      editor.canvas.remove(activeObject);
      editor.canvas.renderAll();
    }
  };

  useEffect(() => {
    if (editor?.canvas) {
      const canvas = editor.canvas;

      // Attach events
      canvas.on('object:moving', handleObjectMoving);
      canvas.on('selection:created', handleObjectSelected);
      canvas.on('selection:updated', handleObjectSelected);
      canvas.on('selection:cleared', handleObjectDeselected);
      canvas.on('mouse:up', hideGuideLines);

      document.addEventListener('keydown', handleDeleteKey);

      return () => {
        canvas.off('object:moving', handleObjectMoving);
        canvas.off('selection:created', handleObjectSelected);
        canvas.off('selection:updated', handleObjectSelected);
        canvas.off('selection:cleared', handleObjectDeselected);
        canvas.off('mouse:up', hideGuideLines);
        document.removeEventListener('keydown', handleDeleteKey);
        hideGuideLines();
      };
    }
  }, [editor]);

  // Load and center the background image
  const loadAndCenterImage = async (imageUrl) => {
    if (!editor?.canvas) {
      console.warn("⚠ Fabric.js editor is not initialized");
      return;
    }
  
    const isLocal = ["localhost", "127.0.0.1"].includes(window.location.hostname);
  
    if (isLocal) {
      console.log("🌍 Running in LOCAL environment. Using direct image loading.");
      loadAndCenterImageLocal(imageUrl);
    } else {
      console.log("🌍 Running in PRODUCTION. Using Blob + URL method.");
      await loadAndCenterImageServer(imageUrl);
    }
  };
  
  // ✅ Local function (No CORS issues)
  const loadAndCenterImageLocal = (imageUrl) => {
    const img = new window.Image();
    img.src = imageUrl;
  
    img.onload = () => {
      if (editor?.canvas) {
        const canvasWidth = editor.canvas.getWidth();
        const canvasHeight = editor.canvas.getHeight();
        const scaleX = canvasWidth / img.width;
        const scaleY = canvasHeight / img.height;
        const scaleToFit = Math.min(scaleX, scaleY);
  
        editor.canvas.setBackgroundImage(
          imageUrl,
          editor.canvas.renderAll.bind(editor.canvas),
          {
            originX: "center",
            originY: "center",
            top: canvasHeight / 2,
            left: canvasWidth / 2,
            scaleX: scaleToFit,
            scaleY: scaleToFit,
          }
        );
  
        console.log("✅ Image loaded locally!");
      }
    };
  
    img.onerror = () => console.error("❌ Failed to load local image:", imageUrl);
  };
  
  // ✅ Server function (Handles CORS issues)
  const loadAndCenterImageServer = async (imageUrl) => {
    try {
      const response = await fetch(imageUrl, { mode: "cors" });
      const blob = await response.blob();
      const localImageUrl = URL.createObjectURL(blob);
  
      const img = new window.Image();
      img.src = localImageUrl;
  
      img.onload = () => {
        if (editor?.canvas) {
          const canvasWidth = editor.canvas.getWidth();
          const canvasHeight = editor.canvas.getHeight();
          const scaleX = canvasWidth / img.width;
          const scaleY = canvasHeight / img.height;
          const scaleToFit = Math.min(scaleX, scaleY);
  
          editor.canvas.setBackgroundImage(
            localImageUrl,
            editor.canvas.renderAll.bind(editor.canvas),
            {
              originX: "center",
              originY: "center",
              top: canvasHeight / 2,
              left: canvasWidth / 2,
              scaleX: scaleToFit,
              scaleY: scaleToFit,
            }
          );
  
          console.log("✅ Image loaded from server successfully!");
        }
      };
    } catch (error) {
      console.error("❌ Failed to load server image:", error);
    }
  };
  

  // Add overlay image
  const addOverlayImage = async (imageUrl) => {
    if (!editor?.canvas) {
      console.warn("⚠ Fabric.js editor is not initialized");
      return;
    }
  
    const isLocal = ["localhost", "127.0.0.1"].includes(window.location.hostname);
  
    if (isLocal) {
      console.log("🌍 Running in LOCAL environment. Using direct image loading.");
      addOverlayImageLocal(imageUrl);
    } else {
      console.log("🌍 Running in PRODUCTION. Using Blob + URL method.");
      await addOverlayImageServer(imageUrl);
    }
  };
  
  // ✅ Local function (No CORS issues)
  const addOverlayImageLocal = (imageUrl) => {
    const img = new window.Image();
    img.src = imageUrl;
  
    img.onload = () => {
      if (!editor?.canvas) {
        console.warn("⚠ Fabric.js editor is not initialized");
        return;
      }
  
      const maxImgSize = 150;
      const scaleX = maxImgSize / img.width;
      const scaleY = maxImgSize / img.height;
      const scaleToFit = Math.min(scaleX, scaleY);
      const canvasCenter = editor.canvas.getCenter();
  
      const overlayImage = new window.fabric.Image(img, {
        left: canvasCenter.left,
        top: canvasCenter.top,
        scaleX: scaleToFit,
        scaleY: scaleToFit,
        originX: "center",
        originY: "center",
        cornerSize: 10,
        hasRotatingPoint: true,
        rotatingPointOffset: 40,
        borderColor: "#c5900b",
        cornerColor: "#8a2909",
        transparentCorners: false,
      });
  
      editor.canvas.add(overlayImage).setActiveObject(overlayImage);
      editor.canvas.renderAll();
  
      console.log("✅ Overlay image added locally!");
    };
  
    img.onerror = () => console.error("❌ Failed to load local overlay image:", imageUrl);
  };
  
  // ✅ Server function (Handles CORS issues)
  const addOverlayImageServer = async (imageUrl) => {
    try {
      const response = await fetch(imageUrl, { mode: "cors" });
      const blob = await response.blob();
      const localImageUrl = URL.createObjectURL(blob);
  
      const img = new window.Image();
      img.src = localImageUrl;
  
      img.onload = () => {
        if (!editor?.canvas) {
          console.warn("⚠ Fabric.js editor is not initialized");
          return;
        }
  
        const maxImgSize = 150;
        const scaleX = maxImgSize / img.width;
        const scaleY = maxImgSize / img.height;
        const scaleToFit = Math.min(scaleX, scaleY);
        const canvasCenter = editor.canvas.getCenter();
  
        const overlayImage = new window.fabric.Image(img, {
          left: canvasCenter.left,
          top: canvasCenter.top,
          scaleX: scaleToFit,
          scaleY: scaleToFit,
          originX: "center",
          originY: "center",
          cornerSize: 10,
          hasRotatingPoint: true,
          rotatingPointOffset: 40,
          borderColor: "#c5900b",
          cornerColor: "#8a2909",
          transparentCorners: false,
        });
  
        editor.canvas.add(overlayImage).setActiveObject(overlayImage);
        editor.canvas.renderAll();
  
        console.log("✅ Overlay image added from server successfully!");
      };
    } catch (error) {
      console.error("❌ Failed to load overlay image from server:", error);
    }
  };

  // ✅ Cargar imagen de fondo cuando `selectedImageUrl` cambia
  useEffect(() => {
    if (selectedImageUrl) {
      loadAndCenterImage(selectedImageUrl);
    }
  }, [selectedImageUrl]);

  // Load images when props change
  useEffect(() => {
    if (editor?.canvas) {
      const parentDiv = document.querySelector(".editor-wrap");
      const canvasWidth = parentDiv.clientWidth;
      const canvasHeight = parentDiv.clientHeight;

      editor.canvas.setDimensions({ width: canvasWidth, height: canvasHeight });

      loadAndCenterImage(selectedImageUrl || baseImage);
    }
  }, [editor, selectedImageUrl]);

  useEffect(() => {
    if (editor && overlayImageUrl) {
      addOverlayImage(overlayImageUrl);
    }
  }, [editor, overlayImageUrl]);
  

  return <FabricJSCanvas className="imageEditorCanvas" onReady={onReady} />;
});

export default ImageEditor;