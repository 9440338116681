// src/components/Shop/Shop.js

import React, { useState } from 'react';
import { ToastContainer } from 'react-toastify';
import Categories from './Categories/Categories';
import ProductDetail from './ProductDetail/ProductDetail';
import Services from './ProductDetail/Services/Services';

export default function Shop() {
  const [selectedModel, setSelectedModel] = useState(null); // Almacenar el modelo seleccionado
  const [overlayImageUrl, setOverlayImageUrl] = useState(''); // Imagen de superposición

  // Handler para seleccionar el modelo completo desde Categories
  const handleModelSelect = (model) => {
    console.log("📌 Modelo seleccionado desde Categorías:", model);
    setSelectedModel(model); // Guardar el modelo seleccionado
  };

  // Handler para seleccionar una imagen de superposición
  const handleOverlayImageSelect = (imageUrl) => {
    console.log("📌 Imagen de superposición seleccionada:", imageUrl);
    setOverlayImageUrl(imageUrl);
  };

  return (
    <>
      <ToastContainer />
      {/* Pasar handleModelSelect a Categories */}
      <Categories onModelSelect={handleModelSelect} />

      {/* Pasar el modelo seleccionado y la imagen de superposición a ProductDetail */}
      <ProductDetail
        selectedModel={selectedModel}
        overlayImageUrl={overlayImageUrl}
        onOverlayImageSelect={handleOverlayImageSelect}
      />

      <Services />
    </>
  );
}