// src/components/utils/shippingCalculator.js

const BASE_RATE = 5.00; // Base price in USD
const PER_KM_RATE = 0.10; // Cost per km in USD
const WEIGHT_RATE = 0.50; // Additional cost per kg
const ITEM_WEIGHT = 0.3; // Each item weighs 0.3kg
const BASE_DELIVERY_DAYS = 3; // Minimum delivery days

/**
 * Calculates the shipping cost and estimated delivery date based on distance and total items.
 * @param {number} distanceKm - Distance in kilometers.
 * @param {number} totalItems - Total quantity of items in the cart.
 * @returns {{ cost: number, estimatedDelivery: string }} - Shipping cost and estimated delivery date.
 */
export const calculateShippingCost = (distanceKm, totalItems) => {
    // Initial cost based on distance
    let cost = BASE_RATE + (distanceKm * PER_KM_RATE);

    // Calculate total weight
    const totalWeight = totalItems * ITEM_WEIGHT;
    console.log(`📦 Total Order Weight: ${totalWeight.toFixed(2)} kg`);

    // Add weight-based cost (every kg counts)
    cost += totalWeight * WEIGHT_RATE;
    console.log(`⚖️ Weight-Based Charge: ${totalWeight.toFixed(2)} kg * $${WEIGHT_RATE}/kg`);

    // Calculate estimated delivery date
    let estimatedDays = BASE_DELIVERY_DAYS;
    if (distanceKm > 1000) estimatedDays += 1; // +1 extra day if > 1000km
    if (distanceKm > 2000) estimatedDays += 2; // +2 extra days if > 2000km

    const estimatedDate = new Date();
    estimatedDate.setDate(estimatedDate.getDate() + estimatedDays);
    const formattedDate = estimatedDate.toLocaleDateString("en-US", { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' });

    console.log(`📅 Estimated Delivery Date: ${formattedDate}`);

    return { cost: parseFloat(cost.toFixed(2)), estimatedDelivery: formattedDate };
};