// src/components/ShoppingCart/ShoppingCart.js

import React, { useState, useEffect } from "react";
import { PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";
import { auth } from "../../firebaseConfig";

import { calculateShippingCost } from "../utils/shippingCalculator";
import { haversineDistance } from "../utils/geoUtils";

import { toast, ToastContainer } from "react-toastify";
import "./ShoppingCart.css";

const API_BASE_URL = `${process.env.REACT_APP_API_BASE_URL}`;
const PROJECT_KEY = "ijrr_user";

const ShoppingCart = () => {
    const [cartItems, setCartItems] = useState([]);
    const [loading, setLoading] = useState(true);

    const [isImageModalOpen, setIsImageModalOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState("");

    const [checkoutStep, setCheckoutStep] = useState(1);
    const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
    const [isClearCartModalOpen, setIsClearCartModalOpen] = useState(false);
    const [selectedOrderId, setSelectedOrderId] = useState(null);
    const [isConfirmed, setIsConfirmed] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [isSaving, setIsSaving] = useState(false);

    const [validationErrors, setValidationErrors] = useState([]);
    const [isValidationModalOpen, setIsValidationModalOpen] = useState(false);

    const [deliveryFee, setDeliveryFee] = useState(null);
    const [isCalculating, setIsCalculating] = useState(false); // Controls loading state
    const [estimatedDelivery, setEstimatedDelivery] = useState(""); // Stores delivery date
    const [displayAddress, setDisplayAddress] = useState(""); // Stores formatted address

    const [isPaying, setIsPaying] = useState(false);
    const [showPayPal, setShowPayPal] = useState(false);
    const [{ isPending }] = usePayPalScriptReducer();

    const [userAddress, setUserAddress] = useState({
        firstName: "",
        lastName: "",
        email: "",
        address: "",
        city: "",
        state: "",
        country: "",
        postalCode: "",
        mobile: ""
    });

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((firebaseUser) => {
            if (firebaseUser) {
                fetchPendingOrders(firebaseUser.uid);
                fetchCustomerData(firebaseUser.uid);
            } else {
                setLoading(false);
            }
        });

        return () => unsubscribe();
    }, []);

    const fetchPendingOrders = async (uid) => {
        try {
            const response = await fetch(`${API_BASE_URL}/orders/pending/${uid}`);
            if (!response.ok) throw new Error("Failed to fetch pending orders");

            const data = await response.json();
            // Ensure numbers are correctly formatted
            const formattedOrders = data.pending_orders.map((item) => ({
                ...item,
                price: parseFloat(item.price), // Convert string to number
                tax: parseFloat(item.tax), // Convert string to number
                total: parseFloat(item.total), // Convert string to number
            }));

            setCartItems(formattedOrders);
        } catch (error) {
            console.error("❌ Error fetching pending orders:", error);
            toast.error("Failed to load pending orders", { autoClose: 2000 });
        } finally {
            setLoading(false);
        }
    };

    const confirmRemoveItem = (orderId) => {
        setSelectedOrderId(orderId);
        setIsConfirmationModalOpen(true);
    };

    const removeItem = async () => {
        try {
            if (!selectedOrderId) return;

            const response = await fetch(`${API_BASE_URL}/orders/delete`, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ order_id: selectedOrderId, firebase_uid: auth.currentUser.uid }),
            });

            if (!response.ok) throw new Error("Failed to delete order");

            setCartItems(cartItems.filter((item) => item.order_id !== selectedOrderId));
            setCheckoutStep(1);
            toast.success("Order deleted successfully!", { autoClose: 2000 });
        } catch (error) {
            console.error("❌ Error deleting order:", error);
            toast.error("Failed to delete order", { autoClose: 2000 });
        } finally {
            setIsConfirmationModalOpen(false);
            setSelectedOrderId(null);
        }
    };

    const confirmClearCart = () => {
        setIsClearCartModalOpen(true);
    };

    const clearCart = async () => {
        try {
            const response = await fetch(`${API_BASE_URL}/orders/clear-pending`, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ firebase_uid: auth.currentUser.uid }),
            });

            if (!response.ok) throw new Error("Failed to clear cart");

            setCartItems([]);
            toast.success("All items cleared successfully!", { autoClose: 2000 });
        } catch (error) {
            console.error("❌ Error clearing cart:", error);
            toast.error("Failed to clear cart", { autoClose: 2000 });
        } finally {
            setIsClearCartModalOpen(false);
        }
    };

    const calculateTotal = () => {
        const subtotal = cartItems.reduce((acc, item) => acc + item.price * item.quantity, 0);
        const tax = cartItems.reduce((acc, item) => acc + item.tax * item.quantity, 0);
        return { subtotal, tax, total: subtotal + tax };
    };

    const { subtotal, tax, total } = calculateTotal();

    // Fetch user profile data
    const fetchCustomerData = async (uid) => {
        try {
            const response = await fetch(`${API_BASE_URL}/customers/${uid}`);
            if (!response.ok) throw new Error("Failed to fetch customer data");

            const data = await response.json();
            const customer = data.customer;

            setUserAddress({
                firstName: customer.first_name || "",
                lastName: customer.last_name || "",
                email: customer.email || "",
                address: customer.address || "",
                city: customer.city || "",
                state: customer.state || "",
                country: customer.country || "",
                postalCode: customer.postal_code || "",
                mobile: customer.mobile || ""
            });

            localStorage.setItem(PROJECT_KEY, JSON.stringify(customer)); // 🔥 Save in localStorage
        } catch (error) {
            console.error("❌ Error fetching customer data:", error);
            toast.error("Failed to load user data", { autoClose: 2000 });
        }
    };

    const handleInputChange = (e) => {
        setUserAddress({ ...userAddress, [e.target.name]: e.target.value });
    };

    const editDeliveryInfo = () => {
        setCheckoutStep(2);
        setIsPaying(false);
        setShowPayPal(false);
        setIsEditing(true);
        setIsConfirmed(false); // Reset confirmation when editing
    };

    // Save updated address info
    const saveDeliveryInfo = async () => {
        setIsSaving(true);
        try {
            const response = await fetch(`${API_BASE_URL}/customers/${auth.currentUser.uid}/update-address`, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    address: userAddress.address,
                    city: userAddress.city,
                    state: userAddress.state,
                    country: userAddress.country,
                    postal_code: userAddress.postalCode
                })
            });

            if (!response.ok) throw new Error("Failed to update address");

            toast.success("Delivery information updated successfully!", { autoClose: 2000 });
            setIsEditing(false);
        } catch (error) {
            console.error("❌ Error updating address:", error);
            toast.error("Failed to save changes", { autoClose: 2000 });
        } finally {
            setIsSaving(false);
        }
    };

    const handleProceedToPay = async () => {
        const missingFields = [];

        // Check if any required field is empty
        if (!userAddress.address.trim()) missingFields.push("Address");
        if (!userAddress.state.trim()) missingFields.push("State");
        if (!userAddress.country.trim()) missingFields.push("Country");
        if (!userAddress.postalCode.trim()) missingFields.push("Postal Code");

        if (missingFields.length > 0) {
            setValidationErrors(missingFields);
            setIsValidationModalOpen(true); // Show validation error modal
            return;
        }

        setCheckoutStep(3);
        setIsCalculating(true);
        setDeliveryFee(null);
        setEstimatedDelivery("");
        setDisplayAddress("");

        console.log("⏳ Calculating shipping cost...");

        // Calculate total quantity of all items in the cart
        const totalQuantity = cartItems.reduce((sum, item) => sum + item.quantity, 0);
        console.log(`🛒 Total Items in Cart: ${totalQuantity}`);

        try {
            // Fetch lat/lon from Nominatim API
            const response = await fetch(
                `https://nominatim.openstreetmap.org/search?postalcode=${userAddress.postalCode}&country=Mexico&format=json`
            );

            if (!response.ok) throw new Error("Failed to fetch location data");

            const data = await response.json();

            if (data.length === 0) {
                toast.error("Invalid postal code. Please check and try again.", { autoClose: 2000 });
                setIsCalculating(false);
                return;
            }

            const { lat, lon, display_name } = data[0];

            console.log(`📍 Postal Code: ${userAddress.postalCode}`);
            console.log(`🌎 Display Name: ${display_name}`);
            console.log(`🌍 Coordinates → Latitude: ${lat}, Longitude: ${lon}`);

            const pvLat = 20.6534;
            const pvLon = -105.2253;

            const distanceKm = haversineDistance(pvLat, pvLon, parseFloat(lat), parseFloat(lon));
            console.log(`📏 Distance from Puerto Vallarta: ${distanceKm.toFixed(2)} km`);

            const { cost: shippingCost, estimatedDelivery } = calculateShippingCost(distanceKm, totalQuantity);

            console.log("📦 Shipping Calculation Details:");
            console.log(`➡️ Distance Input: ${distanceKm.toFixed(2)} km`);
            console.log(`➡️ Total Items: ${totalQuantity}`);
            console.log(`💰 Final Estimated Shipping Cost: $${shippingCost.toFixed(2)}`);
            console.log(`📅 Estimated Delivery Date: ${estimatedDelivery}`);

            setTimeout(() => {
                setDeliveryFee(shippingCost);
                setEstimatedDelivery(estimatedDelivery);
                setDisplayAddress(display_name);
                setIsCalculating(false);
            }, 3000);
        } catch (error) {
            console.error("❌ Error fetching location data:", error);
            toast.error("Error fetching location data. Try again.", { autoClose: 2000 });
            setIsCalculating(false);
        }
    };

    const generateOrderId = () => {
        return `ORD-${Math.floor(100000 + Math.random() * 900000)}`; // Random 6-digit order ID
    };

    const generateTrackingNumber = () => {
        return `TRK-${Math.floor(1000000000 + Math.random() * 9000000000)}`; // Random 10-digit tracking number
    };

    return (
        <div>
            <ToastContainer position="top-right" theme="light" />
            <section className="shopping space-pb-80">
                <div className="custom-container">
                    <div className="shopping-cart-container">
                        {cartItems.length > 0 && (
                            <div className="cart-header">
                                <h4>
                                    You have {cartItems.length} {cartItems.length === 1 ? "item" : "items"} in your shopping cart.
                                </h4>
                                <a href="/shop" className="continue-shopping-link"><i className="fa fa-shopping-cart"></i> Continue Shopping</a>
                            </div>
                        )}
                        {loading ? (
                            <p>Loading...</p>
                        ) : cartItems.length === 0 && checkoutStep !== 4 ? (
                            <div className="empty-cart-message">
                                <h3>Your shopping cart is empty</h3>
                                <p>Looks like you haven't added anything to your cart yet.</p>
                                <a className="btn btn-primary" href="/shop">Continue Shopping</a>
                            </div>
                        ) : checkoutStep === 4 ? (
                            <div className="col-md-12 checkout-box payment-success-box">
                                <div className="success-icon">
                                    <i className="fa fa-check-circle"></i>
                                </div>

                                <h3>Payment Successful!</h3>

                                <div className="success-message">
                                    <p><strong>Thank you, {userAddress.firstName}!</strong></p>
                                    <p>Your payment has been successfully processed.</p>
                                    <p>Your order is confirmed, and we are preparing it for shipment.</p>

                                    <div className="delivery-info-success">
                                        <p><i className="fa fa-calendar"></i> <strong>Estimated Delivery Date:</strong> {estimatedDelivery}</p>
                                        <p><i className="fa fa-envelope"></i> <strong>Confirmation Email Sent To:</strong> {userAddress.email}</p>
                                        <p><i className="fa fa-truck"></i> <strong>Tracking Information:</strong>
                                            Order ID: <strong>{generateOrderId()}</strong>,
                                            Tracking Number: <strong>{generateTrackingNumber()}</strong>
                                        </p>
                                    </div>
                                </div>

                                <a href="/shop" className="btn btn-primary btn-continue">
                                    <i className="fa fa-shopping-cart"></i> Continue Shopping
                                </a>
                            </div>
                        ) : (
                            <>
                                <table className="cart-table">
                                    <thead>
                                        <tr>
                                            <th>Product</th>
                                            <th>Product Name</th>
                                            <th>Quantity</th>
                                            <th>Price</th>
                                            <th>Tax</th>
                                            <th>Total</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {cartItems.map((item) => (
                                            <tr key={item.order_id}>
                                                <td>
    <img
        src={item.product_preview}
        alt={item.product_name}
        className="cart-image"
        onClick={() => {
            setSelectedImage(item.product_preview);
            setIsImageModalOpen(true);
        }}
        style={{ cursor: "pointer" }} // Make it clear it's clickable
    />
</td>
                                                <td><strong>{item.product_name}</strong></td>
                                                <td>{item.quantity}</td>
                                                <td>${item.price.toFixed(2)}</td>
                                                <td>${item.tax.toFixed(2)}</td>
                                                <td>${((item.price + item.tax) * item.quantity).toFixed(2)}</td>
                                                <td>
                                                    <button className="delete-btn" onClick={() => confirmRemoveItem(item.order_id)}>
                                                        <i className="fa fa-trash"></i>
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>

                                <div className="cart-buttons">
                                    <button className="btn btn-clear" onClick={confirmClearCart}><i className="fa fa-trash"></i> Clear Shopping Cart</button>
                                    {checkoutStep === 1 && (
                                        <button className="btn btn-primary" onClick={() => setCheckoutStep(2)}>
                                            <i className="fa fa-shopping-cart"></i> Go to Checkout
                                        </button>
                                    )}
                                </div>

                                <div className="row checkout-section">
                                    {/* Delivery Information Column */}
                                    {checkoutStep >= 2 && (
                                        <div className="col-md-6 checkout-box">
                                            <h3>Delivery Information</h3>

                                            <form className="delivery-form">
                                                <div className="row">
                                                    {/* First Name & Last Name */}
                                                    <div className="col-md-6 mb-14">
                                                        <label className="form-label">First Name:</label>
                                                        <input type="text" className="form-control" value={userAddress.firstName} disabled />
                                                    </div>
                                                    <div className="col-md-6 mb-14">
                                                        <label className="form-label">Last Name:</label>
                                                        <input type="text" className="form-control" value={userAddress.lastName} disabled />
                                                    </div>

                                                    {/* Email & Address */}
                                                    <div className="col-md-6 mb-14">
                                                        <label className="form-label">Email:</label>
                                                        <input type="email" className="form-control" value={userAddress.email} disabled />
                                                    </div>
                                                    <div className="col-md-6 mb-14">
                                                        <label className="form-label">Address:</label>
                                                        <input type="text" className="form-control" name="address" value={userAddress.address} onChange={handleInputChange} disabled={!isEditing} placeholder="Enter address..." />
                                                    </div>

                                                    {/* City & State */}
                                                    <div className="col-md-6 mb-14">
                                                        <label className="form-label">City:</label>
                                                        <input type="text" className="form-control" name="city" value={userAddress.city} onChange={handleInputChange} disabled={!isEditing} placeholder="Enter city..." />
                                                    </div>
                                                    <div className="col-md-6 mb-14">
                                                        <label className="form-label">State:</label>
                                                        <input type="text" className="form-control" name="state" value={userAddress.state} onChange={handleInputChange} disabled={!isEditing} placeholder="Enter state..." />
                                                    </div>

                                                    {/* Country & Postal Code */}
                                                    <div className="col-md-6 mb-14">
                                                        <label className="form-label">Country:</label>
                                                        <input type="text" className="form-control" name="country" value={userAddress.country} onChange={handleInputChange} disabled={!isEditing} placeholder="Enter country..." />
                                                    </div>
                                                    <div className="col-md-6 mb-14">
                                                        <label className="form-label">Postal Code:</label>
                                                        <input type="text" className="form-control" name="postalCode" value={userAddress.postalCode} onChange={handleInputChange} disabled={!isEditing} placeholder="Enter postal code..." />
                                                    </div>

                                                    {/* Buttons */}
                                                    <div className="col-12 d-flex justify-content-between align-items-center mt-10">
                                                        {/* Small Edit Button with Icon */}
                                                        <button type="button" className={`btn ${isEditing ? "btn-success btn-save" : "btn-secondary btn-edit"}`} onClick={isEditing ? saveDeliveryInfo : editDeliveryInfo}>
                                                            <i className={`fa ${isEditing ? "fa-save" : "fa-pencil-alt"}`}></i> {isEditing ? "Save" : "Edit"}
                                                        </button>

                                                        {/* Stylized Proceed to Pay Button */}
                                                        {checkoutStep === 2 && (
                                                            <button type="button" className="btn btn-primary btn-proceed" disabled={isEditing} onClick={handleProceedToPay}>
                                                                <i className="fa fa-clipboard-check"></i> Review & Proceed
                                                            </button>
                                                        )}
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    )}

                                    {/* Order Summary Column */}
                                    {checkoutStep >= 3 && checkoutStep !== 4 && (
                                        <div className="col-md-6 checkout-box">
                                            <h3>Order Summary</h3>

                                            <div className="summary-details">
                                                <p><strong>Subtotal:</strong> ${subtotal.toFixed(2)}</p>
                                                <p><strong>Tax:</strong> ${tax.toFixed(2)}</p>
                                                <p><strong>Delivery Fee: </strong>
                                                    {isCalculating ? (
                                                        <span className="calculating-text"> Calculating <span className="dots"></span></span>
                                                    ) : deliveryFee !== null ? `$${deliveryFee.toFixed(2)}` : "Pending..."}
                                                </p>
                                            </div>

                                            <hr className="summary-divider" />

                                            {deliveryFee !== null && (
                                                <>
                                                    <p className="total-price-summary"><strong>Total:</strong> ${total.toFixed(2)}</p>

                                                    <div className="delivery-info">
                                                        <p><i className="fa fa-calendar"></i> <strong>Estimated Delivery Date:</strong> {estimatedDelivery}</p>
                                                        <p><i className="fa fa-map-marker"></i> <strong>Delivery Address:</strong> {userAddress.address}, {userAddress.city}, {userAddress.state}</p>
                                                        <p><i className="fa fa-map-pin"></i> <strong>Location:</strong> {displayAddress}</p>
                                                    </div>
                                                </>
                                            )}

                                            {!isCalculating && deliveryFee !== null && !isPaying && (
                                                <button
                                                    className="btn btn-checkout"
                                                    onClick={() => {
                                                        setIsPaying(true);  // Ocultar el botón al hacer clic
                                                        setShowPayPal(true);
                                                    }}
                                                >
                                                    <i className="fa fa-credit-card"></i> Submit Order & Pay
                                                </button>
                                            )}

                                            {showPayPal && (
                                                <div className="paypal-container" >
                                                    {isPending ? (
                                                        <p>Loading PayPal...</p>
                                                    ) : (
                                                        <PayPalButtons
                                                            style={{ layout: "vertical" }}
                                                            createOrder={(data, actions) => {
                                                                return actions.order.create({
                                                                    purchase_units: [
                                                                        {
                                                                            amount: {
                                                                                currency_code: "USD",
                                                                                value: total.toFixed(2),
                                                                            },
                                                                        },
                                                                    ],
                                                                });
                                                            }}
                                                            onApprove={async (data, actions) => {
                                                                return actions.order.capture().then(async (details) => {
                                                                    console.log("✅ Payment Successful:", details);
                                                                    toast.success(`Payment Successful! Thank you, ${details.payer.name.given_name}`);

                                                                    // Step 1: Call API to clear pending orders
                                                                    try {
                                                                        const response = await fetch(`${API_BASE_URL}/orders/clear-pending`, {
                                                                            method: "POST",
                                                                            headers: { "Content-Type": "application/json" },
                                                                            body: JSON.stringify({ firebase_uid: auth.currentUser.uid }),
                                                                        });

                                                                        if (!response.ok) throw new Error("Failed to clear cart after payment");

                                                                        console.log("🛒 Pending orders cleared after payment!");
                                                                    } catch (error) {
                                                                        console.error("❌ Error clearing cart after payment:", error);
                                                                        toast.error("Something went wrong clearing the cart, but your payment was successful.", { autoClose: 3000 });
                                                                    }

                                                                    // Step 2: Empty cart and show success message
                                                                    setCartItems([]);  // Clear items
                                                                    setCheckoutStep(4); // Move to success screen
                                                                });
                                                            }}
                                                            onError={(err) => {
                                                                console.error("❌ PayPal Checkout Error:", err);
                                                                toast.error("Payment failed, please try again.");
                                                            }}
                                                        />
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    )}


                                </div>
                            </>
                        )}
                    </div>
                </div>
            </section>

            {isImageModalOpen && (
    <div className="image-modal" onClick={() => setIsImageModalOpen(false)}>
        
        <div className="image-modal-content" onClick={(e) => e.stopPropagation()}>
            <button className="close-btn" onClick={() => setIsImageModalOpen(false)}>×</button>
            <img src={selectedImage} alt="Product Preview" className="full-image" />
        </div>
    </div>
)}

            {/* ✅ Confirmation Modal for Removing an Item */}
            {isConfirmationModalOpen && (
                <div className="error-modal" onClick={() => setIsConfirmationModalOpen(false)}>
                    <div className="error-content" onClick={(e) => e.stopPropagation()}>
                        <button className="close-btn" onClick={() => setIsConfirmationModalOpen(false)}>×</button>
                        <h3>Confirm Deletion</h3>
                        <p>Are you sure you want to delete this item from your cart?</p>
                        <div className="error-buttons">
                            <button className="btn-secondary" onClick={() => setIsConfirmationModalOpen(false)}>Cancel</button>
                            <button className="btn-primary" onClick={removeItem}>Delete</button>
                        </div>
                    </div>
                </div>
            )}

            {/* ✅ Confirmation Modal for Clearing the Cart */}
            {isClearCartModalOpen && (
                <div className="error-modal" onClick={() => setIsClearCartModalOpen(false)}>
                    <div className="error-content" onClick={(e) => e.stopPropagation()}>
                        <button className="close-btn" onClick={() => setIsClearCartModalOpen(false)}>×</button>
                        <h3>Confirm Clear Cart</h3>
                        <p>Are you sure you want to clear all items from your cart?</p>
                        <div className="error-buttons">
                            <button className="btn-secondary" onClick={() => setIsClearCartModalOpen(false)}>Cancel</button>
                            <button className="btn-primary" onClick={clearCart}>Clear Cart</button>
                        </div>
                    </div>
                </div>
            )}

            {isValidationModalOpen && (
                <div className="error-modal address-modal" onClick={() => setIsValidationModalOpen(false)}>
                    <div className="error-content" onClick={(e) => e.stopPropagation()}>
                        <button className="close-btn" onClick={() => setIsValidationModalOpen(false)}>×</button>
                        <h3>Missing Required Fields</h3>
                        <p>Please fill in the following required fields before proceeding:</p>
                        <ul>
                            {validationErrors.map((error, index) => (
                                <li key={index}><strong>{error}</strong></li>
                            ))}
                        </ul>
                        <button className="btn-primary" onClick={() => setIsValidationModalOpen(false)}>OK</button>
                    </div>
                </div>
            )}
        </div>
    );

};

export default ShoppingCart;

