// src/components/Profile/Profile.js

import React, { useState, useEffect } from "react";
import { auth } from "../../firebaseConfig";
import { updateProfile } from "firebase/auth";
import { toast, ToastContainer } from "react-toastify";
import "./Profile.css";
import "react-toastify/dist/ReactToastify.css";

const API_BASE_URL = `${process.env.REACT_APP_API_BASE_URL}/customers`;
const PROJECT_KEY = "ijrr_user";

const Profile = () => {
  const [editing, setEditing] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [editableUser, setEditableUser] = useState(null);
  const [validPhoto, setValidPhoto] = useState(true);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((firebaseUser) => {
      if (firebaseUser) {
        fetchCustomerData(firebaseUser.uid);
      } else {
        setLoading(false);
        setError("No user found.");
      }
    });

    return () => unsubscribe(); // Cleanup al desmontar
  }, []);

  const fetchCustomerData = async (uid) => {
    try {
      const response = await fetch(`${API_BASE_URL}/${uid}`);
      if (!response.ok) throw new Error("Failed to fetch data");

      const data = await response.json();
      setEditableUser(data.customer);
      localStorage.setItem(PROJECT_KEY, JSON.stringify(data.customer)); // 🔥 Guardamos en localStorage
      setLoading(false);
    } catch (err) {
      console.error("❌ Error fetching user data:", err);
      setError("Failed to load profile");
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditableUser((prev) => ({ ...prev, [name]: value }));
  };

  const saveProfile = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/${editableUser.firebase_uid}`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(editableUser),
      });

      if (!response.ok) throw new Error("Failed to save changes");

      if (auth.currentUser) {
        await updateProfile(auth.currentUser, {
            displayName: `${editableUser.first_name} ${editableUser.last_name}`
        });
        console.log("✅ Firebase Auth profile updated.");
    }

      toast.success("Profile updated successfully!", { autoClose: 2000 });
      setEditing(false);
    } catch (error) {
      console.error("❌ Error saving profile:", error);
      setError("Failed to save changes.");
      toast.error("Failed to save changes.", { autoClose: 2000 });
    }
  };

  const getInitials = (name) => {
    if (!name) return "U";
    return name.charAt(0).toUpperCase();
  };

  return (
    <div>
      <ToastContainer position="top-right" theme="light" />
      <section className="profile space-pb-80">
        <div className="container">
          <div className="profile-container">
            {loading ? (
              <div className="loading">Loading...</div>
            ) : error ? (
              <div className="error">{error}</div>
            ) : (
              <>
                <div className="profile-header">
                  <div className="profile-avatar">
                    {editableUser?.avatar && validPhoto ? (
                      <img
                        src={editableUser.avatar}
                        alt="User Photo"
                        onError={() => setValidPhoto(false)}
                      />
                    ) : (
                      <div className="default-avatar">
                        {getInitials(`${editableUser.first_name} ${editableUser.last_name}`)}
                      </div>
                    )}
                    <button className="edit-avatar-btn">Change</button>
                  </div>
                  <div className="profile-basic-info">
                    <h2>{`${editableUser.first_name} ${editableUser.last_name}` || "No name set"}</h2>
                    <p>{editableUser.email}</p>
                  </div>
                  <button className="edit-profile-btn" onClick={() => (editing ? saveProfile() : setEditing(true))}>
                    {editing ? "Save" : "Edit Profile"}
                  </button>
                </div>

                <div className="profile-sections">
                  <ProfileSection title="Basic Info">
                    <div className="row">
                      <div className="col-6">
                        <ProfileField label="First Name" name="first_name" value={editableUser.first_name} editing={editing} onChange={handleChange} />
                      </div>
                      <div className="col-6">
                        <ProfileField label="Last Name" name="last_name" value={editableUser.last_name} editing={editing} onChange={handleChange} />
                      </div>
                      <div className="col-6">
                        <ProfileField label="Gender" name="gender" value={editableUser.gender} editing={editing} onChange={handleChange} type="select" />
                      </div>
                      <div className="col-6">
                        <ProfileField label="Birthday" name="birthday" value={editableUser.birthday} editing={editing} onChange={handleChange} type="date" />
                      </div>
                    </div>
                  </ProfileSection>

                  <ProfileSection title="Contact Info">
                    <div className="row">
                      <div className="col-6">
                        <ProfileField label="Email" name="email" value={editableUser.email} editing={false} />
                      </div>
                      <div className="col-6">
                        <ProfileField label="Phone" name="mobile" value={editableUser.mobile} editing={editing} onChange={handleChange} />
                      </div>
                    </div>
                  </ProfileSection>

                  <ProfileSection title="Address">
                    <div className="row">
                      <div className="col-6">
                        <ProfileField label="Country" name="country" value={editableUser.country} editing={editing} onChange={handleChange} />
                      </div>
                      <div className="col-6">
                        <ProfileField label="State" name="state" value={editableUser.state} editing={editing} onChange={handleChange} />
                      </div>
                      <div className="col-6">
                        <ProfileField label="City" name="city" value={editableUser.city} editing={editing} onChange={handleChange} />
                      </div>
                      <div className="col-6">
                        <ProfileField label="Street" name="address" value={editableUser.address} editing={editing} onChange={handleChange} />
                      </div>
                      <div className="col-6">
                        <ProfileField label="Postal Code" name="postal_code" value={editableUser.postal_code} editing={editing} onChange={handleChange} />
                      </div>
                    </div>
                  </ProfileSection>

                  <ProfileSection title="Social Links">
                    <div className="row">
                      <div className="col-6">
                        <ProfileField label="Facebook" name="facebook" value={editableUser.facebook} editing={editing} onChange={handleChange} />
                      </div>
                      <div className="col-6">
                        <ProfileField label="X" name="twitter" value={editableUser.twitter} editing={editing} onChange={handleChange} />
                      </div>
                      <div className="col-6">
                        <ProfileField label="Instagram" name="instagram" value={editableUser.instagram} editing={editing} onChange={handleChange} />
                      </div>
                    </div>
                  </ProfileSection>
                </div>
              </>
            )}
          </div>
        </div>
      </section>
    </div>
  );
};

const ProfileSection = ({ title, children }) => (
  <div className="profile-section">
    <h3>{title}</h3>
    {children}
  </div>
);

const ProfileField = ({ label, name, value, editing, onChange, type = "text" }) => (
  <div className="profile-field">
    <label>{label}:</label>
    {type === "select" ? (
      <select name={name} value={value} disabled={!editing} onChange={onChange}>
        <option value="male">Male</option>
        <option value="female">Female</option>
        <option value="other">Other</option>
      </select>
    ) : (
      <input type={type} name={name} value={value || ""} readOnly={!editing} onChange={onChange} />
    )}
  </div>
);

export default Profile;