// src/components/Auth/AuthModal.js
import React, { useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import { auth, googleProvider } from "../../firebaseConfig";
import { createUserWithEmailAndPassword, signInWithPopup, signInWithEmailAndPassword, updateProfile } from "firebase/auth";

import "./AuthModal.css";

const PROJECT_KEY = "ijrr_user"; // 🔥 Clave única para IJRR

export default function AuthModal({ isOpen, onClose, onLoginSuccess }) {
    const [isLogin, setIsLogin] = useState(true);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [mobile, setMobile] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [termsAgreed, setTermsAgreed] = useState(false);

    const clearForm = () => {
        setFirstName("");
        setLastName("");
        setMobile("");
        setEmail("");
        setPassword("");
        setConfirmPassword("");
        setTermsAgreed(false);
    };

    const showSuccessToast = (firstName, lastName) => {
        toast.success(
            <span>
                Welcome back <strong>{firstName} {lastName}</strong>!
            </span>, 
            { autoClose: 2000 }
        );
    };

    const handleAuthAction = async (e) => {
        e.preventDefault();
    
        if (!isLogin && password !== confirmPassword) {
            toast.error("Passwords do not match", {autoClose: 1500});
            return;
        }
    
        if (!isLogin && !termsAgreed) {
            toast.error("You must agree to the terms and conditions", {autoClose: 1500});
            return;
        }
    
        try {
            let userCredential;
            let userData = {};
    
            if (isLogin) {
                // 🔹 Usuario existente: Iniciar sesión con email/password
                userCredential = await signInWithEmailAndPassword(auth, email, password);
                const user = userCredential.user;
    
                // 🔥 Intentamos obtener los datos del usuario desde la API
                const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/customers/${user.uid}`);
                if (response.ok) {
                    const data = await response.json();
                    userData = {
                        firebase_uid: user.uid,
                        first_name: data.customer.first_name || "Unknown",
                        last_name: data.customer.last_name || "",
                        mobile: data.customer.mobile || "",
                        email: user.email,
                        sign_up_method: "email_pass",
                        status: "active",
                        created_at_firebase: new Date(user.metadata.creationTime).toISOString(),
                        last_login_at_firebase: new Date(user.metadata.lastSignInTime).toISOString(),
                    };
                } else {
                    console.warn("⚠ No se encontró usuario en la base de datos, puede que sea nuevo.");
                }
            } else {
                // 🔹 Nuevo usuario: Registrarse con email/password
                userCredential = await createUserWithEmailAndPassword(auth, email, password);
                const user = userCredential.user;
    
                await updateProfile(user, {
                    displayName: `${firstName} ${lastName}`
                });
    
                userData = {
                    firebase_uid: user.uid,
                    first_name: firstName,
                    last_name: lastName,
                    mobile: mobile,
                    email: user.email,
                    sign_up_method: "email_pass",
                    status: "active",
                    created_at_firebase: new Date(user.metadata.creationTime).toISOString(),
                    last_login_at_firebase: new Date(user.metadata.lastSignInTime).toISOString(),
                };
    
                // 🔥 Guardamos al usuario en la API (solo si es nuevo)
                await registerUser(userData);
            }
    
            // 🔥 Guardamos los datos en localStorage y actualizamos el header
            localStorage.setItem(PROJECT_KEY, JSON.stringify(userData));
            console.log("✅ User authenticated!", userData);

            // Toast
            showSuccessToast(userData.first_name, userData.last_name);
    
            onLoginSuccess(userData);
            clearForm();
            onClose();
        } catch (error) {
            console.error("❌ Authentication error:", error);
            toast.error("Authentication failed. Please try again.", {autoClose: 1500});
        }
    };
    
    const handleGoogleSignIn = async () => {
        try {
            const result = await signInWithPopup(auth, googleProvider);
            const user = result.user;
    
            const names = user.displayName ? user.displayName.split(" ") : ["", ""];
            let userData = {
                firebase_uid: user.uid,
                first_name: names[0],
                last_name: names.slice(1).join(" "),
                email: user.email,
                avatar: user.photoURL,
                sign_up_method: "google",
                status: "active",
                created_at_firebase: new Date(user.metadata.creationTime).toISOString(),
                last_login_at_firebase: new Date(user.metadata.lastSignInTime).toISOString(),
            };
    
            // 🔥 Verificar si el usuario ya existe en la API
            let response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/customers/${user.uid}`);
    
            if (response.ok) {
                // ✅ Usuario encontrado en la API, obtenemos sus datos
                const data = await response.json();
                userData = {
                    ...userData,
                    first_name: data.customer.first_name,
                    last_name: data.customer.last_name,
                    mobile: data.customer.mobile,
                };
                console.log("✅ Usuario encontrado en la API:", userData);
            } else {
                console.warn("⚠ Usuario no encontrado en la API, registrándolo...");
    
                // 🔥 Si no existe en la API, lo registramos
                const registerResponse = await fetch(`${process.env.REACT_APP_API_BASE_URL}/customers`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(userData),
                });
    
                if (!registerResponse.ok) {
                    const errorData = await registerResponse.json();
                    console.error("❌ Error al registrar usuario:", errorData);
                    toast.error("Failed to save user data. Please try again.", {autoClose: 1000});
                    return;
                } else {
                    console.log("✅ Usuario registrado correctamente en la API!");
                }
    
                // 🔥 VOLVEMOS A CONSULTAR EL USUARIO DESPUÉS DEL REGISTRO
                response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/customers/${user.uid}`);
                if (response.ok) {
                    const data = await response.json();
                    userData = {
                        ...userData,
                        first_name: data.customer.first_name,
                        last_name: data.customer.last_name,
                        mobile: data.customer.mobile,
                    };
                    console.log("✅ Usuario confirmado en la API después del registro:", userData);
                }
            }
    
            // 🔥 Guardamos en localStorage
            localStorage.setItem(PROJECT_KEY, JSON.stringify(userData));
            console.log("✅ Google Sign-In Successful!", userData);

            showSuccessToast(userData.first_name, userData.last_name);
    
            onLoginSuccess(userData);
            onClose();
        } catch (error) {
            console.error("❌ Google sign-in error:", error);
        }
    };

    const registerUser = async (userData) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/customers`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(userData),
            });

            if (!response.ok) {
                const errorData = await response.json();
                console.error("❌ Error response data:", errorData);
                alert("Failed to save user data. Please try again.");
            } else {
                console.log("✅ User registered successfully!");
            }
        } catch (error) {
            console.error("❌ Failed to register user:", error);
            toast.error("Error connecting to the server.", {autoClose: 1000});
        }
    };

    return isOpen ? (
        <div className="auth-modal">
            
            <div className="auth-modal-content">
                <button className="auth-close-btn" onClick={onClose}>✖</button>
                <h3 className="auth-title">{isLogin ? "Login" : "Create Your Account"}</h3>

                <form className={`auth-form ${isLogin ? "login-form" : "register-form"}`} onSubmit={handleAuthAction}>
                    {!isLogin && (
                        <div className="auth-two-columns">
                            <input className="auth-input" type="text" placeholder="First Name" value={firstName} onChange={(e) => setFirstName(e.target.value)} required />
                            <input className="auth-input" type="text" placeholder="Last Name" value={lastName} onChange={(e) => setLastName(e.target.value)} required />
                        </div>
                    )}

                    {!isLogin && <input className="auth-input" type="tel" placeholder="Mobile" value={mobile} onChange={(e) => setMobile(e.target.value)} required />}
                    <input className="auth-input" type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} required />
                    <input className="auth-input" type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} required />

                    {!isLogin && <input className="auth-input" type="password" placeholder="Confirm Password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} required />}

                    {!isLogin && (
                        <label className="auth-checkbox">
                            <input type="checkbox" checked={termsAgreed} onChange={(e) => setTermsAgreed(e.target.checked)} />
                            I Agree to Terms and Conditions
                        </label>
                    )}

                    <button type="submit" className="auth-btn">{isLogin ? "Login" : "Sign Up"}</button>
                </form>

                <button className="auth-google-btn" onClick={handleGoogleSignIn}>Sign in with Google</button>

                <p className="auth-toggle" onClick={() => setIsLogin(!isLogin)}>
                    {isLogin ? "Don't have an account? Sign up" : "Already have an account? Login"}
                </p>
            </div>
        </div>
    ) : null;
}