// src/components/Home/Home.js

import React from 'react';
import { useLocation } from 'react-router-dom';
import HomeSlider from './HomeSlider/HomeSlider';

const Home = () => {
  const location = useLocation(); // Get the current route location

  return (
    <>
      {/* Use location.pathname as the key to ensure HomeSlider resets */}
      <HomeSlider key={location.pathname} />
    </>
  );
};

export default Home;
