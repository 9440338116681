// src/components/Login/Login.js 

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { auth, googleProvider } from "../../firebaseConfig";
import { signInWithEmailAndPassword, signInWithPopup } from "firebase/auth";
import "react-toastify/ReactToastify.css";
import "./Login.css";

const PROJECT_KEY = "ijrr_user"; // 🔥 Clave única para IJRR

export default function Login({ onLoginSuccess }) {
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const handleLogin = async (e) => {
        e.preventDefault();

        try {
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;

            // 🔥 Intentamos obtener los datos del usuario desde la API
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/customers/${user.uid}`);
            let userData = {
                firebase_uid: user.uid,
                email: user.email,
                sign_up_method: "email_pass",
                status: "active",
                created_at_firebase: new Date(user.metadata.creationTime).toISOString(),
                last_login_at_firebase: new Date(user.metadata.lastSignInTime).toISOString(),
            };

            if (response.ok) {
                const data = await response.json();
                userData = { ...userData, first_name: data.customer.first_name, last_name: data.customer.last_name, mobile: data.customer.mobile };
            } else {
                console.warn("⚠ No se encontró usuario en la base de datos, puede que sea nuevo.");
            }

            // 🔥 Guardamos en localStorage
            localStorage.setItem(PROJECT_KEY, JSON.stringify(userData));
            console.log("✅ User logged in!", userData);

            toast.success("Welcome back!", {autoClose: 1000})

            setTimeout(() => {
                navigate("/profile");
            }, 1500);

            //onLoginSuccess(userData);
        } catch (error) {
            console.error("❌ Login error:", error);
            toast.error("Login failed. Please check your credentials.");
        }
    };

    const handleGoogleSignIn = async () => {
        try {
            const result = await signInWithPopup(auth, googleProvider);
            const user = result.user;
    
            const names = user.displayName ? user.displayName.split(" ") : ["", ""];
            let userData = {
                firebase_uid: user.uid,
                first_name: names[0],
                last_name: names.slice(1).join(" "),
                email: user.email,
                avatar: user.photoURL,
                sign_up_method: "google",
                status: "active",
                created_at_firebase: new Date(user.metadata.creationTime).toISOString(),
                last_login_at_firebase: new Date(user.metadata.lastSignInTime).toISOString(),
            };
    
            // 🔥 Verificamos si el usuario ya existe en la API
            let response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/customers/${user.uid}`);
            
            if (response.ok) {
                // Si el usuario existe, obtenemos sus datos
                const data = await response.json();
                userData = {
                    ...userData,
                    first_name: data.customer.first_name,
                    last_name: data.customer.last_name,
                    mobile: data.customer.mobile,
                };
                console.log("✅ Usuario encontrado en la API:", userData);
            } else {
                console.warn("⚠ Usuario no encontrado en la API, registrándolo...");
    
                // 🔥 Si no existe en la API, lo registramos
                const registerResponse = await fetch(`${process.env.REACT_APP_API_BASE_URL}/customers`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(userData),
                });
    
                if (!registerResponse.ok) {
                    const errorData = await registerResponse.json();
                    console.error("❌ Error al registrar usuario:", errorData);
                    toast.error("Failed to save user data. Please try again.");
                    return;
                } else {
                    console.log("✅ Usuario registrado correctamente en la API!");
                }
    
                // 🔥 VOLVEMOS A CONSULTAR EL USUARIO DESPUÉS DEL REGISTRO
                response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/customers/${user.uid}`);
                if (response.ok) {
                    const data = await response.json();
                    userData = {
                        ...userData,
                        first_name: data.customer.first_name,
                        last_name: data.customer.last_name,
                        mobile: data.customer.mobile,
                    };
                    console.log("✅ Usuario confirmado en la API después del registro:", userData);
                }
            }
    
            // Guardamos en localStorage
            localStorage.setItem(PROJECT_KEY, JSON.stringify(userData));
            console.log("✅ Google Sign-In Successful!", userData);

            toast.success("Welcome back!", {autoClose: 1000})

            setTimeout(() => {
                navigate("/profile");
            }, 1500);
    
            //onLoginSuccess(userData);
        } catch (error) {
            console.error("❌ Google sign-in error:", error);
            toast.error("Google sign-in failed.");
        }
    };

    return (
        <section className="space-pb-80 product-detail-page">
            <ToastContainer autoClose="1500" />
            <div className="login-container">
                <div className="codex-authmain">
                    <form className="codex-authbox" onSubmit={handleLogin}>
                        <div className="group-form text-center">
                            <h3>Login to Your Account</h3>
                            <h6>
                                Don't have an account?{" "}
                                <a className="text-primary text-decoration-underline" href="/register">
                                    Sign up here
                                </a>
                            </h6>
                        </div>

                        <div className="group-form">
                            <label className="form-label">Email</label>
                            <input className="form-control" type="email" placeholder="mail@jollyroger.us.com" value={email} onChange={(e) => setEmail(e.target.value)} required />
                        </div>

                        <div className="group-form">
                            <label className="form-label">Password</label>
                            <input className="form-control" type="password" placeholder="********" value={password} onChange={(e) => setPassword(e.target.value)} required />
                        </div>

                        <button type="submit" className="btn d-block btn-primary-login">Log In</button>

                        <button type="button" className="auth-google-btn" onClick={handleGoogleSignIn}>
                            Sign in with Google
                        </button>
                    </form>
                </div>
            </div>
        </section>
    );
}