// src/layout/Header/Header.js

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { auth } from '../../firebaseConfig';
import { signOut } from 'firebase/auth';

import "react-toastify/ReactToastify.css"
import './Header.css';

const PROJECT_KEY = "ijrr_user";
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export default function Header() {
  const navigate = useNavigate();
  const [user, setUser] = useState(() => JSON.parse(localStorage.getItem(PROJECT_KEY)));
  const [pendingOrders, setPendingOrders] = useState(0);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [validPhoto, setValidPhoto] = useState(true);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((firebaseUser) => {
      if (firebaseUser) {
        setUser(firebaseUser);
        localStorage.setItem(PROJECT_KEY, JSON.stringify(firebaseUser));
        fetchPendingOrders(firebaseUser.uid);
        console.log("✅ (Header.js) Usuario autenticado desde Firebase:", firebaseUser);
      } else {
        setUser(null);
        setPendingOrders(0);
        localStorage.removeItem(PROJECT_KEY);
        console.log("❌ (Header.js) No hay usuario autenticado.");
      }
    });

    return () => unsubscribe();
  }, []);

  const fetchPendingOrders = async (firebaseUid) => {
    try {
      const response = await fetch(`${API_BASE_URL}/customers/${firebaseUid}`);
      if (!response.ok) {
        throw new Error("Failed to fetch pending orders");
      }
      const data = await response.json();
      setPendingOrders(data.pending_orders_count);
    } catch (error) {
      console.error("❌ Error fetching pending orders:", error);
    }
  };

  const handleSignOut = async () => {
    await signOut(auth);
    localStorage.removeItem(PROJECT_KEY);
    setUser(null);
    setDropdownOpen(false);
    setPendingOrders(0);
    console.log("👋 Usuario cerró sesión.");
    toast.info("You have been logged out.", {autoClose: 600});

    setTimeout(() => {
      navigate("/login");
    }, 1400);
  };

  const toggleDropdown = (e) => {
    e.preventDefault();
    setDropdownOpen((prev) => !prev);
    console.log("🔽 Toggle dropdown:", !dropdownOpen);
  };

  const getInitials = (name) => {
    if (!name) return "U"; // Si no hay nombre, usar 'U' de User
    return name.charAt(0).toUpperCase();
  };

  return (
    <header className="cdx-header">
      <ToastContainer />
      <div className="custom-container">
        <div className="row">
          <div className="col-12">
            <div className="header-contian">
              <div className="header-left">
                <div className="brand-logo">
                  <a href="/">
                    <img className="img-fluid" src="../assets/images/ijrr/vertical-logo.png" alt="IJRR" />
                  </a>
                </div>
              </div>
              <div className="header-right">
                <ul className="codex-menu">
                  <li className="menu-item"><a href="/home">Home</a></li>
                  <li className="menu-item"><a href="/about">About</a></li>
                  <li className="menu-item"><a href="/tradition">Tradition</a></li>
                  <li className="menu-item"><a href="/maps">Maps</a></li>
                  <li className="menu-item"><a href="/shop">Shop</a></li>
                  <li className="menu-item"><a href="/pricing">Pricing</a></li>
                  {user && <li className="menu-item"><a href="/profile">Profile</a></li>}
                  {!user && <li className="menu-item"><a href="/login">Log in</a></li>}
                </ul>
              </div>

              {/* 🔥 Ícono del usuario */}
              <div className="nav-iconlist">
                <ul>
                  <li>
                    <a href="/shopping-cart">
                      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9 22C9.55228 22 10 21.5523 10 21C10 20.4477 9.55228 20 9 20C8.44772 20 8 20.4477 8 21C8 21.5523 8.44772 22 9 22Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                        <path d="M20 22C20.5523 22 21 21.5523 21 21C21 20.4477 20.5523 20 20 20C19.4477 20 19 20.4477 19 21C19 21.5523 19.4477 22 20 22Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                        <path d="M1 1H5L7.68 14.39C7.77144 14.8504 8.02191 15.264 8.38755 15.5583C8.75318 15.8526 9.2107 16.009 9.68 16H19.4C19.8693 16.009 20.3268 15.8526 20.6925 15.5583C21.0581 15.264 21.3086 14.8504 21.4 14.39L23 6H6" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                      </svg>
                      {pendingOrders > 0 && (
                        <div className="nav-notification">{pendingOrders}</div>
                      )}
                    </a>
                  </li>
                  <li className="dropdownmenu"
                    style={{ position: "relative" }}
                    onBlur={() => setTimeout(() => setDropdownOpen(false), 200)} // Pequeño delay para evitar que se cierre de inmediato
                    tabIndex={0}>
                    <a className="dropdown-action" href="/" onClick={toggleDropdown}>
                      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M20 21V19C20 17.9391 19.5786 16.9217 18.8284 16.1716C18.0783 15.4214 17.0609 15 16 15H8C6.93913 15 5.92172 15.4214 5.17157 16.1716C4.42143 16.9217 4 17.9391 4 19V21" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                        <path d="M12 11C14.2091 11 16 9.20914 16 7C16 4.79086 14.2091 3 12 3C9.79086 3 8 4.79086 8 7C8 9.20914 9.79086 11 12 11Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                      </svg>
                    </a>

                    {/* 🔥 Si hay usuario autenticado, mostramos su info */}
                    {dropdownOpen && (
                      <ul className="dropdownitem-list">
                        {user ? (
                          <>
                            <li className="user-info">
                            {user.photoURL && validPhoto ? (
                                <img
                                  src={user.photoURL}
                                  alt="Profile"
                                  className="profile-pic"
                                  onError={() => setValidPhoto(false)}
                                />
                              ) : (
                                <div className="default-avatar">
                                  {getInitials(user?.displayName)}
                                </div>
                              )}
                              <br />
                              <span className="user-name">{user.displayName || "User"}</span>
                              <br />
                              <small className="user-email">{user.email}</small>
                            </li>
                            <li><button className="sign-out-btn" onClick={handleSignOut}>Sign Out</button></li>
                          </>
                        ) : (
                          <>
                            <li><a href="/login">Login</a></li>
                            <li><a href="/register">Register</a></li>
                          </>
                        )}
                      </ul>
                    )}
                  </li>
                </ul>
                <div className="mobilemenu-toggle"><i className="icofont-navigation-menu"></i></div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </header>
  );
}